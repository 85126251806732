/* globals zoomSdk */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Countdown from './Countdown';
import Canvas from '../utilities/Canvas';
import SetTimer from './SetTimer';
import {
  BackButton,
  ControlButton,
  TimeButtons,
  DisplayTimerForOthersButton,
} from './Buttons';
import {
  determineBackgroundColor,
  determineTextColor,
  determineTimerColor,
  formatTime,
} from '../utils';
import useSupportRunningContext from '../hooks/useSupportRunningContext';
import useOperationSystem from '../hooks/useOperatingSystem';
import './Home.css';
import ControlSwitchModal from '../utilities/ControlSwitchModal';
import useEnterEscKeyPress from '../hooks/useEnterEscKeyPress';
import useDynamicIndicatorClientCheck from '../hooks/useDynamicIndicatorClientCheck';
import useWithSound from '../hooks/useWithSound';
import NotificationToast from '../utilities/NotificationToast';
import CustomTooltip from '../utilities/CustomTooltip/CustomTooltip';
import ToastComponent from '../utilities/Toast Component/ToastComponent';
import useIsDesktopCheck from '../hooks/useIsDesktopCheck';
import { mobileAudioPlay } from '../utilities/Mobile/playMobileAlarm';
import { SoundButton } from '../utilities/MusicInTimer/SoundButton';
import {
  selectIsTimerAudioOn,
  selectedTimerMusicOption,
} from '../utilities/MusicInTimer/reducer';
import useCountNegativeAfterAlarm from '../hooks/useCountNegativeAfterAlarm';
import useStorePreferredMusic from '../hooks/useStorePreferredMusic';

function Home({
  currentTab,
  timerIsRunning,
  setTimerIsRunning,
  timerVideoToggleOn,
  setTimerVideoToggleOn,
  stopwatchIsOn,
  stopwatchVideoToggleOn,
  dynamicTimerOnEvent,
  dynamicTimerRemoveEvent,
  timerTime,
  setTimerTime,
  myDynamicIndicator,
  userContext,
  countdownStatus,
  setCountdownStatus,
  somethingWentWrong,
  setSomethingWentWrong,
  size,
  width,
  timerAudioSettingFromDI,
  isVideoDimensionsChange,
  isTimerDISet,
  isStopwatchDISet,
  setMeetingTimerDisabled,
  meetingTimerDisabled,
  clientSupportNegativeAlarm,
  setClientSupportNegativeAlarm,
  screenName,
  isVideoTurnedOn,
}) {
  // vfDidDisplayOnVideo is the source of truth if VF is successfully display on the video
  const [vfDidDisplayOnVideo, setVfDidDisplayOnVideo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [displayCustomInput, setDisplayCustomInput] = useState(true); // Can be removed
  const [startTime, setStartTime] = useState(0);
  const [resumedTime, setResumedTime] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const myTimerAudioToggleOn = useSelector(selectIsTimerAudioOn);
  const [counter, setCounter] = useState(0);
  const [countdownKey, setCountdownKey] = useState(0);
  const [timerEnd, setTimerEnd] = useState(false);
  const [presetTime, setPresetTime] = useState(false);
  const toastReset = useRef(null);
  const timerWorker = useRef(null);
  const [timerId, setTimerId] = useState(0);
  const [oldHostSupport, setOldHostSupport] = useState(false);

  /**
   * Version and Device Type Control Variables.
   */
  const dynamicIndicatorClientCheck = useDynamicIndicatorClientCheck();
  const supportRunningContext = useSupportRunningContext();
  const isMacOS = useOperationSystem();
  const clientSupportWithSound = useWithSound();
  const isDesktop = useIsDesktopCheck();
  const selectedMusicOption = useSelector(selectedTimerMusicOption);
  const negativeTimerClient = useCountNegativeAfterAlarm();

  const andTheWinnerRef = useRef(null);
  const beethovenRef = useRef(null);
  const goldenRef = useRef(null);
  const ohlalaRef = useRef(null);
  const onTheLaunchpadRef = useRef(null);
  const pencilsDownRef = useRef(null);
  const timesUpRef = useRef(null);
  const vamosRef = useRef(null);
  const vegasRef = useRef(null);
  const weMadeItRef = useRef(null);
  const defaultAlarmRef = useRef(null);

  const activeTimerRunning = screenName
    ? screenName + "'s" + ' timer is running. Controls are disabled.'
    : 'An active timer is running. Controls are disabled.';

  const refsList = [
    {
      id: 0,
      ref: defaultAlarmRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/defaultAlarm.wav`,
    },
    {
      id: 1,
      ref: timesUpRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/times_up.mp3`,
    },
    {
      id: 2,
      ref: vegasRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/vegas.mp3`,
    },
    {
      id: 3,
      ref: pencilsDownRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/pencils_down.mp3`,
    },
    {
      id: 4,
      ref: andTheWinnerRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/and_the_winner_is.mp3`,
    },
    {
      id: 5,
      ref: beethovenRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/beethoven_begins.mp3`,
    },
    {
      id: 6,
      ref: onTheLaunchpadRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/on_the_launchpad.mp3`,
    },
    {
      id: 7,
      ref: weMadeItRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/we_made_it.mp3`,
    },
    {
      id: 8,
      ref: vamosRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/vamos.mp3`,
    },
    {
      id: 9,
      ref: goldenRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/golden_ticket.mp3`,
    },
    {
      id: 10,
      ref: ohlalaRef,
      src: `${process.env.PUBLIC_URL}/MusicFiles/ohh_lala.mp3`,
    },
  ];

  /**
   * This event puts the minutes input box in focus on app start. Emulating a double click behaviur inside the box.
   */
  window.onanimationend = function () {
    const input = document.getElementById('input-minutes');
    if (input && isMacOS) {
      input.focus();
      input.select();
    }
  };

  /** set preferred music option */
  useStorePreferredMusic();

  /**
   * setTimerTimeWithConstraint function take time as an argument and sets contains on it.
   * The time constraint range is 0 to 360000 seconds or 0 secs to 99 hrs 59 minutes and 59 secs, both in positive and negative.
   * This code sets Time to negative only when client supports negative alarm is not in main client.
   * @type {(function(*): void)|*}
   */
  const setTimerTimeWithConstraint = useCallback(
    (time) => {
      if (time <= 0 && time > -360000) {
        clientSupportNegativeAlarm && supportRunningContext
          ? setTimerTime(time)
          : setTimerTime(0);
      } else if (time <= -360000) {
        clientSupportNegativeAlarm && supportRunningContext
          ? setTimerTime(-359999)
          : setTimerTime(0);
      } else if (time >= 0 && time < 360000) {
        setTimerTime(time);
      } else {
        setTimerTime(359999); // Max input: 99 hours, 59 minutes, 59 seconds
      }
    },
    [clientSupportNegativeAlarm]
  );

  const formatTimer = useCallback(
    (time) => {
      let hours = formatTime(time).hours;
      let minutes = formatTime(time).minutes;
      let seconds = formatTime(time).seconds;
      if (time === 0) {
        if (!timerIsRunning) {
          hours = '00';
          minutes = '00';
          seconds = '00';
        }
      }
      return (
        <>
          {countdownStatus ? (
            <>
              <Countdown
                countdownKey={countdownKey}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                startTime={startTime}
                timerTime={timerTime}
                timerStatus={timerIsRunning}
                myDynamicIndicator={myDynamicIndicator}
                setSomethingWentWrong={setSomethingWentWrong}
                myTimerAudioToggleOn={myTimerAudioToggleOn}
                timerAudioSettingFromDI={timerAudioSettingFromDI}
                isTimerDISet={isTimerDISet}
              />
            </>
          ) : (
            <SetTimer
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              timerTime={timerTime}
              setTimerTime={setTimerTime}
              setTimerTimeWithConstraint={setTimerTimeWithConstraint}
              startTimerOnEnterPress={startTimer}
              stopwatchIsOn={stopwatchIsOn}
              openModal={openModal}
              onContinueModal={onContinueModal}
              supportRunningContext={supportRunningContext}
              timerVideoToggleOn={myTimerAudioToggleOn}
              dynamicIndicatorClientCheck={dynamicIndicatorClientCheck}
            />
          )}
        </>
      );
    },
    [
      countdownKey,
      countdownStatus,
      startTime,
      timerIsRunning,
      timerTime,
      setTimerTimeWithConstraint,
      stopwatchIsOn,
      openModal,
      supportRunningContext,
      myTimerAudioToggleOn,
      dynamicIndicatorClientCheck,
    ]
  );

  const formatTimerCanvas = useCallback(
    (time) => {
      if (timerEnd === true) {
        time = 0;
      }
      let hours = formatTime(time).hours;
      let minutes = formatTime(time).minutes;
      let seconds = formatTime(time).seconds;

      return (
        <>
          {supportRunningContext && countdownStatus && (
            <Canvas
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              startTime={startTime}
              timerTime={timerTime}
              watchOnVideo={timerVideoToggleOn}
              watchType='timer'
              vfOnVideo={vfDidDisplayOnVideo}
              setVfOnVideo={setVfDidDisplayOnVideo}
              size={size}
              width={width}
              isVideoDimensionsChange={isVideoDimensionsChange}
              isVideoTurnedOn={isVideoTurnedOn}
            />
          )}
        </>
      );
    },
    [
      supportRunningContext,
      countdownStatus,
      startTime,
      timerTime,
      timerVideoToggleOn,
      size,
      width,
      isVideoDimensionsChange,
      timerIsRunning,
      isVideoTurnedOn,
    ]
  );

  /**
   * playSound function plays the Timer Alarm audio in loop.
   * @type {(function(): void)|*}
   */
  const playSound = useCallback(() => {
    let currentSelectedMusicRef = refsList[selectedMusicOption.id].ref;
    if (currentSelectedMusicRef.current.src) {
      currentSelectedMusicRef.current.muted = false;
      currentSelectedMusicRef.current.play().catch((error) => {
        console.log(error);
      });
    }
  }, [selectedMusicOption, refsList]);

  /**
   * pauseSound function pauses the Timer Alarm audio.
   * @type {(function(): void)|*}
   */
  // only try to pause music if a music option is selected. -1 is when sound is off.
  const pauseSound = useCallback(() => {
    if (selectedMusicOption.id !== -1) {
      let currentSelectedMusicRef = refsList[selectedMusicOption.id].ref;
      if (currentSelectedMusicRef.current.src) {
        currentSelectedMusicRef.current.pause();
        currentSelectedMusicRef.current.currentTime = 0;
      }
    }
  }, [selectedMusicOption, refsList]);

  /**
   * If a personal Timer is started on new client supporting negative Timer for the first Timer,
   * setClient support negative Alarm true.
   */
  function newClientPersonalTimer() {
    if (!isTimerDISet && negativeTimerClient) {
      setClientSupportNegativeAlarm(true);
    }
  }

  /**
   * This Controller function is designed to control the Start, Resume and Pause Buttons of Timer.
   */
  const timerStartLogic = () => {
    newClientPersonalTimer();
    setOldHostSupport(false); // Temporary variable for old client host subscriber support.
    setTimerEnd(false);
    setStartTimestamp(Date.now());
    setCounter(counter + 1);
    setResumedTime(timerTime);
    /* This state means that the Timer is Off. It is either not started yet or it has ended. */
    if (!countdownStatus && !timerIsRunning) {
      setTimerIsRunning(true); //Start the Timer
      setCountdownStatus(true);
    }
    /* This state means that the Timer is On and currently running. */
    if (countdownStatus && timerIsRunning) {
      setTimerIsRunning(false); //Pause the Timer
    }
    /* This state means that the Timer is On and currently paused. */
    if (countdownStatus && !timerIsRunning) {
      setTimerIsRunning(true); // Resume the Timer
    }
  };
  let startClicked = false;
  function setDynamicIndicator() {
    if (
      supportRunningContext &&
      timerVideoToggleOn &&
      dynamicIndicatorClientCheck === 'dISupportedClient'
    ) {
      const timerColor = determineTimerColor(timerTime, startTime);
      const textColor = determineTextColor(timerTime);
      const backgroundColor = determineBackgroundColor(timerTime);
      if (!countdownStatus && !timerIsRunning && !startClicked) {
        /* This state means that the Timer is Off. It is either not started yet or it has ended. */
        startClicked = true;
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'start',
              direction: 'down',
              start: timerTime * 1000,
              withSound: myTimerAudioToggleOn,
              showNotification: true,
              songChoice: parseInt(selectedMusicOption.id),
              countNegativeAfterAlarm: true,
            },
            borderColor: timerColor,
            textColor: textColor,
            backgroundColor: backgroundColor,
          })
          .catch((error) => {
            if (
              error.toString() ===
              'Error: Meeting dropdown feature is disabled.'
            ) {
              setMeetingTimerDisabled(true);
            } else {
              setSomethingWentWrong(true);
            }
          });
      } else if (countdownStatus && timerIsRunning) {
        /* This state means that the Timer is On and currently running. */
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'pause',
              withSound: myTimerAudioToggleOn,
              showNotification: true,
              songChoice: parseInt(selectedMusicOption.id),
            },
            borderColor: timerColor,
            textColor: textColor,
            backgroundColor: backgroundColor,
          })
          .catch((error) => {
            setSomethingWentWrong(true);
          });
      } else if (countdownStatus && !timerIsRunning) {
        /* This state means that the Timer is On and currently paused. */
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'resume',
              withSound: myTimerAudioToggleOn,
              showNotification: true,
              songChoice: parseInt(selectedMusicOption.id),
              countNegativeAfterAlarm: true,
            },
            borderColor: timerColor,
            textColor: textColor,
            backgroundColor: backgroundColor,
          })
          .catch((error) => {
            setSomethingWentWrong(true);
          });
      }
    } else {
      if (!countdownStatus && !timerIsRunning) {
        window.localStorage.setItem('LastSetTime', timerTime.toString());
      }
      timerStartLogic();
    }
  }
  const startTimer = () => {
    if (
      supportRunningContext &&
      stopwatchIsOn &&
      stopwatchVideoToggleOn &&
      timerVideoToggleOn
    ) {
      setOpenModal(true);
    } else {
      setDynamicIndicator();
    }

    /**
     * Use this function call to play audio when on a Mobile device.
     * Regular audio playing operations do not function in Mobile and need a specific user
     * action to trigger Audio.
     * This function ideally should only be used for Mobile devices in out of meeting context,
     * when audio is supported in Dynamic Indicator for Mobile.
     */
    if (!isDesktop && myTimerAudioToggleOn) {
      mobileAudioPlay({
        countdownStatus,
        timerIsRunning,
        timerTime,
        timerId,
        setTimerId,
        defaultAlarmRef,
      });
    }
  };

  function resetTimerLogic() {
    setOldHostSupport(false); //Temporary support.
    setPresetTime(false);
    if (toastReset.current !== null) {
      clearTimeout(toastReset.current);
    }
    if (zoomSdk && timerVideoToggleOn && vfDidDisplayOnVideo) {
      zoomSdk.callZoomApi('removeVirtualForeground').catch((error) => {
        /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs do it function but still throws an error saying something went wrong.
        Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
      });
      setVfDidDisplayOnVideo(false);
    }
    pauseSound();
    setTimerIsRunning(false);
    setCountdownStatus(false);
    const localStorageTime = parseInt(
      window.localStorage.getItem('LastSetTime')
    );
    const lastSetTime =
      localStorageTime === null || isNaN(localStorageTime)
        ? startTime
        : localStorageTime;
    setTimerTimeWithConstraint(lastSetTime);
    setCounter(0);
    setDisplayCustomInput(true);
  }
  let cancelClicked = false;
  const resetTimer = useCallback(() => {
    if (isTimerDISet && !cancelClicked) {
      zoomSdk.callZoomApi('removeDynamicIndicator').catch((error) => {
        setSomethingWentWrong(true);
      });
      cancelClicked = true;
    } else {
      resetTimerLogic();
    }
  }, [
    timerVideoToggleOn,
    pauseSound,
    dynamicIndicatorClientCheck,
    setTimerTimeWithConstraint,
    supportRunningContext,
    vfDidDisplayOnVideo,
    isTimerDISet,
  ]);

  // a custom hook to listen to 'enter' and 'escape' key press
  useEnterEscKeyPress({
    isCurrentTab: currentTab === 'home',
    openModal,
    startHandler: startTimer,
    onContinueModal,
    isDynamicIndicatorSet: isTimerDISet,
    myDynamicIndicator,
    onCancelModal,
    resetWatch: resetTimer,
    timerTime: timerTime,
  });
  /**
   * This Effect runs when dynamicTimerRemoveEvent is true.
   * i.e. when an onRemoveDynamicIndicator event is received by the App.
   * It effectively resets the timer and removes the Virtual foreground.
   */
  useEffect(() => {
    if (dynamicTimerRemoveEvent === true) {
      resetTimerLogic();
    }
  }, [dynamicTimerRemoveEvent]);
  useEffect(() => {
    return () => {
      resetTimerLogic();
      zoomSdk.callZoomApi('removeVirtualForeground').catch((error) => {
        /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs work but still throws an error saying something went wrong.
        Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
      });
    };
  }, []);

  /**
   * This Effect runs when dynamicTimerOnEvent is true.
   * i.e. when an onSetDynamicIndicator event is received by the App, or when the app is started or refreshed.
   */
  useEffect(() => {
    if (dynamicTimerOnEvent === true || dynamicTimerOnEvent === false) {
      timerStartLogic();
    }
  }, [dynamicTimerOnEvent]);

  /**
   * This effect implements the keyboard key '1' effect.
   * The timer container changes to 1 minute after pressing key '1'.
   */
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (
        event.key === '1' &&
        document.activeElement.id !== 'input-seconds' &&
        document.activeElement.id !== 'input-minutes' &&
        document.activeElement.id !== 'input-hours'
      ) {
        event.preventDefault();
        setTimerTime(60);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (
      (timerIsRunning || countdownStatus) &&
      !timerVideoToggleOn &&
      vfDidDisplayOnVideo
    ) {
      zoomSdk.callZoomApi('removeVirtualForeground').catch((error) => {
        /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs work but still throws an error saying something went wrong.
        Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
      });
      setVfDidDisplayOnVideo(false);
    }
  }, [timerVideoToggleOn]);

  useEffect(() => {
    if (!timerIsRunning && !countdownStatus) {
      setStartTime(timerTime);
      if (presetTime) {
        startTimer();
      }
    }
  }, [timerIsRunning, countdownStatus, timerTime, presetTime]);

  useEffect(() => {
    if (
      ((clientSupportNegativeAlarm && supportRunningContext) ||
        timerTime > 0) &&
      timerIsRunning
    ) {
      if (timerWorker.current === null) {
        let TimerWorker = process.env.PUBLIC_URL + '/TimerWorker.js';
        timerWorker.current = new Worker(TimerWorker);
        timerWorker.current.postMessage({
          startTime: startTime,
          startTimestamp: startTimestamp,
          resumedTime: resumedTime,
          counter: counter,
        });
        timerWorker.current.onmessage = (e) => {
          setTimerTimeWithConstraint(e.data);
        };
      }
    }
    return () => {
      if (timerWorker.current) {
        timerWorker.current.terminate();
        timerWorker.current = null;
      }
    };
  }, [
    startTime,
    timerTime,
    startTimestamp,
    resumedTime,
    timerIsRunning,
    counter,
    setTimerTimeWithConstraint,
  ]);

  /**
   * This is a temporary useEffect to cover one specific flaw in Stop Negative Timer Versioning.
   * The stop button does not show in the case when participant publisher supports negative timer
   * and host subscriber is on old client that does not support negative timer.
   * This effect will help show that button. The Timeout of 5.3 seconds is,
   * because you don't want the stop button to show when both publisher & subscriber are on an old client.
   * This delay will allow the timer to close.
   */
  useEffect(() => {
    if (
      timerTime === 0 &&
      countdownStatus &&
      supportRunningContext &&
      !clientSupportNegativeAlarm &&
      isTimerDISet
    ) {
      if (userContext.role === 'host') {
        setTimeout(() => setOldHostSupport(true), 6100);
      }
    }
  }, [timerTime, countdownStatus, userContext, supportRunningContext]);

  useEffect(() => {
    if (timerTime === 0 && timerIsRunning) {
      // Used to stop the Timer at 0 and end negative counter in old client or out in Main client.
      if (!clientSupportNegativeAlarm || !supportRunningContext) {
        setTimerEnd(true);
      }
      // First condition: if client doesn't support withSound, let timer app play the audio.
      /* Second condition: if client support withSound and "Show Timer to all" is toggled off and DI not set, let timer app play the audio. 
         This condition can handle bug: on withSound supported client, if subscriber turns off "Show timer to all" before someone else sets DI,
         the participant can hear audio twice. One from the client and the other from timer app. So, we cannot just rely timerVideoToggleOn state
         to determine if we have to let timer app play audio when a personal timer is running. 
      */
      if (
        isDesktop &&
        (!clientSupportWithSound ||
          !supportRunningContext ||
          (clientSupportWithSound && !timerVideoToggleOn && !isTimerDISet))
      ) {
        myTimerAudioToggleOn ? playSound() : pauseSound();
        setTimeout(() => pauseSound(), 6000);
      }

      //When inMain client or old client,reset Timer.
      if (
        (!clientSupportNegativeAlarm && myDynamicIndicator) ||
        !supportRunningContext ||
        (!isTimerDISet && !clientSupportNegativeAlarm)
      ) {
        setTimeout(() => resetTimer(), 6000);
      }
    }
  }, [
    timerTime,
    timerIsRunning,
    myTimerAudioToggleOn,
    playSound,
    pauseSound,
    clientSupportWithSound,
    timerVideoToggleOn,
  ]);

  /**
   * This effect runs for Timer Subscriber only, in the case when a personal timer is running and a participant
   * publishes a Dynamic Indicator.
   */
  useEffect(() => {
    if ((isTimerDISet || isStopwatchDISet) && !myDynamicIndicator) {
      resetTimerLogic();
    }
  }, [isTimerDISet, isStopwatchDISet, myDynamicIndicator]);

  function onCloseModal() {
    setOpenModal(false);
  }
  function onContinueModal() {
    setOpenModal(false);
    setDynamicIndicator();
  }
  function onCancelModal() {
    setOpenModal(false);
  }

  /*  {somethingWentWrong && (
    <ToastComponent
      usage='error_toast'
      body='Something went wrong! Please try again.'
    />
  )}*/
  return (
    <>
      {countdownStatus && timerIsRunning && timerTime <= 0 ? (
        <>
          <ToastComponent usage='end_toast' body="Time's Up!" />
        </>
      ) : (
        <></>
      )}
      {meetingTimerDisabled && (
        <ToastComponent
          usage='error_toast'
          body='Meeting Timers have been disabled by the Host.'
        />
      )}
      {timerVideoToggleOn && formatTimerCanvas(timerTime)}

      <div
        className='Home__container'
        style={{
          visibility: currentTab === 'home' ? 'inherit' : 'hidden',
        }}
      >
        {isTimerDISet && !myDynamicIndicator && (
          <NotificationToast
            title={activeTimerRunning}
            dismiss={false}
            type='info'
          />
        )}
        {openModal && (
          <ControlSwitchModal
            title={'Begin Timer for Everyone'}
            message={
              "You're about to show your timer to everyone. It will replace the active stopwatch."
            }
            onCancelFunction={onCancelModal}
            onCloseFunction={onCloseModal}
            onContinueFunction={onContinueModal}
          />
        )}

        {!countdownStatus && <SoundButton refs={refsList} />}

        {(countdownStatus || displayCustomInput) && supportRunningContext && (
          <DisplayTimerForOthersButton
            displayOnVideo={timerVideoToggleOn}
            onClickFunction={() => setTimerVideoToggleOn(!timerVideoToggleOn)}
            text='Show timer to all'
            // disable toggle when timer is running or in pause state.
            disabled={countdownStatus}
          />
        )}

        {displayCustomInput && (
          <div>
            <div className='Main_container'>
              <div
                data-testid='timerContainer'
                className={`Home__timer_container${
                  (!clientSupportNegativeAlarm || !supportRunningContext) &&
                  timerTime === 0 &&
                  timerIsRunning
                    ? ' fade'
                    : ''
                }`}
              >
                {formatTimer(timerTime)}
              </div>

              {!countdownStatus && (
                <div className='Home__start_button'>
                  <button
                    id='input_start_button'
                    data-tooltip-id='timer-startbutton-tooltip'
                    data-tooltip-delay-show={300}
                    className='Home__time_button'
                    disabled={timerTime === 0}
                    onClick={startTimer}
                  >
                    Start
                  </button>
                  <CustomTooltip
                    text='Keyboard shortcut'
                    keyName={isMacOS ? 'return' : 'enter'}
                    id='timer-startbutton-tooltip'
                  />
                </div>
              )}
              {!countdownStatus && (
                <TimeButtons
                  setStartTime={setStartTime}
                  setPresetTime={setPresetTime}
                  setTimerTimeWithConstraint={setTimerTimeWithConstraint}
                />
              )}
            </div>
            {countdownStatus && timerTime > 0 && (
              <div className='Home__controlTime'>
                <div className='Countdown__cancel_button'>
                  <button
                    id='timer_cancel_button'
                    data-tooltip-id='timer-cancelbutton-tooltip'
                    data-tooltip-delay-show={300}
                    className='Home__time_button'
                    onClick={resetTimer}
                    disabled={
                      isTimerDISet &&
                      !myDynamicIndicator &&
                      userContext.role !== 'host'
                    }
                  >
                    Cancel
                  </button>
                  <CustomTooltip
                    text='Keyboard shortcut'
                    keyName='esc'
                    id='timer-cancelbutton-tooltip'
                  />
                </div>
                <ControlButton
                  timerTime={timerTime}
                  startTimer={startTimer}
                  timerStatus={timerIsRunning}
                  countdownStatus={countdownStatus}
                  disabled={isTimerDISet && !myDynamicIndicator}
                  isMacOS={isMacOS}
                />
              </div>
            )}
            {(clientSupportNegativeAlarm || oldHostSupport) &&
              countdownStatus &&
              timerTime <= 0 &&
              supportRunningContext && (
                <div className='Home__controlTime'>
                  <div className='Countdown__cancel_button'>
                    <button
                      id='timer_cancel_button'
                      className='Home__time_button'
                      onClick={resetTimer}
                      disabled={
                        isTimerDISet &&
                        !myDynamicIndicator &&
                        userContext.role !== 'host'
                      }
                    >
                      Stop
                    </button>
                  </div>
                </div>
              )}
          </div>
        )}

        {displayCustomInput && !countdownStatus && (
          <BackButton onClickFunction={resetTimer} />
        )}
      </div>

      {refsList.map((music) => {
        return <audio src={music.src} ref={music.ref} key={music.id} />;
      })}
    </>
  );
}

export default Home;
