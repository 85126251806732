import { useSelector } from 'react-redux';
import { selectZaConfig } from '../root/reducer';
import { useEffect, useState } from 'react';

// This hook is used to identify if the Timer app is running on desktop or not.
// If Timer app is running on Mobile device (iPhone, Android phone) and iPad, the config.product will be `mobile`. `isDesktop` will be false.
function useIsDesktopCheck() {
  const config = useSelector(selectZaConfig);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (config && config?.product === 'desktop') {
      setIsDesktop(true);
    }
  }, [config]);
  return isDesktop;
}

export default useIsDesktopCheck;
