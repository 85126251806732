import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectZaClientVersion, selectZaRunningContext } from '../root/reducer';

function useSupportRunningContext() {
  const runningContext = useSelector(selectZaRunningContext);
  const clientVersion = useSelector(selectZaClientVersion);
  const [supportRunningContext, setSupportRunningContext] = useState(false);

  useEffect(() => {
    if (
      (runningContext === 'inMeeting' || runningContext === 'inWebinar') &&
      parseInt(clientVersion.split('.').join('')) >= 5960000
    ) {
      setSupportRunningContext(true);
    }
  }, [runningContext, clientVersion]);

  return supportRunningContext;
}

export default useSupportRunningContext;
