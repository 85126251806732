import './ControlSwitchModal.css';
import { CancelSVG } from './CancelSVG';

export default function ControlSwitchModal({
  title,
  message,
  onCloseFunction,
  onCancelFunction,
  onContinueFunction,
}) {
  return (
    <div id='cs_overlay'>
      <div id='cs_modal'>
        <div className='cs_container'>
          <div className='cs_content'>
            <button
              id='cs_exit'
              aria-label='Exit Toast'
              onClick={onCloseFunction}
            >
              <CancelSVG />
            </button>
            <div className='cs_title'>{title}</div>
            <div className='cs_message'>{message}</div>
            <div className='cs_buttons'>
              <button id='cs_cancel' onClick={onCancelFunction}>
                Cancel
              </button>
              <button id='cs_continue' onClick={onContinueFunction}>
                Yes, Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
