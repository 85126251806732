import { useCallback, useEffect, useState } from 'react';
import useVideoSettings from '../hooks/useVideoSettings';
import NotificationToast from './NotificationToast';

export default function VideoSettings({ showError, watchType, showBanner }) {
  const clientSupportVideoSettings = useVideoSettings();
  const [isVideoMirrored, setIsVideoMirrored] = useState(false);

  useEffect(() => {
    const updateVideoSettingsState = (videoSettingsChangeEvent) => {
      setIsVideoMirrored(videoSettingsChangeEvent?.mirrorMyVideo);
    };
    zoomSdk.addEventListener('onVideoSettingsChange', updateVideoSettingsState);
    return () => {
      zoomSdk.removeEventListener(
        'onVideoSettingChange',
        updateVideoSettingsState
      );
    };
  }, []);

  useEffect(() => {
    if (clientSupportVideoSettings) {
      zoomSdk.callZoomApi('getVideoSettings').then((result) => {
        setIsVideoMirrored(result.mirrorMyVideo);
      });
    }
  }, [clientSupportVideoSettings]);

  const turnOnVideoAndTurnOffMirror = useCallback(() => {
    if (clientSupportVideoSettings) {
      turnOnVideo();
      setMirrorMyVideoOff();
    }
  }, [clientSupportVideoSettings]);

  const setMirrorMyVideoOff = useCallback(() => {
    if (clientSupportVideoSettings) {
      zoomSdk
        .callZoomApi('setVideoSettings', { mirrorMyVideo: false })
        .catch((error) => {
          // perhaps show error to user in future
        });
    }
  }, [clientSupportVideoSettings]);

  const turnOnVideo = useCallback(() => {
    if (clientSupportVideoSettings) {
      zoomSdk.callZoomApi('setVideoState', { video: true }).catch((error) => {
        // perhaps show error to user in future
      });
    }
  }, [clientSupportVideoSettings]);

  return (
    <>
      {showBanner && showError && !isVideoMirrored && (
        <NotificationToast
          title={
            <div>
              <a
                className={`${
                  clientSupportVideoSettings
                    ? 'notification-clickable-text'
                    : ''
                }`}
                onClick={turnOnVideo}
              >
                Start Video
              </a>
              <span> to show {watchType} in your video.</span>
            </div>
          }
          dismiss={true}
          type={'error'}
        />
      )}
      {showBanner && !showError && isVideoMirrored && (
        <NotificationToast
          title={
            <div>
              <a
                className={`${
                  clientSupportVideoSettings
                    ? 'notification-clickable-text'
                    : ''
                }`}
                onClick={setMirrorMyVideoOff}
              >
                Turn off video mirroring
              </a>
              <span> to view {watchType} in right direction in your video</span>
            </div>
          }
          dismiss={true}
          type={'error'}
        />
      )}
      {showBanner && showError && isVideoMirrored && (
        <NotificationToast
          title={
            <div>
              <a
                className={`${
                  clientSupportVideoSettings
                    ? 'notification-clickable-text'
                    : ''
                }`}
                onClick={turnOnVideoAndTurnOffMirror}
              >
                Start video and turn off mirroring
              </a>
              <span> to show timer in your video</span>
            </div>
          }
          dismiss={true}
          type={'error'}
        />
      )}
    </>
  );
}
