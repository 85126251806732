import { useSelector } from 'react-redux';
import { selectZaClientVersion } from '../root/reducer';
import { useEffect, useState } from 'react';

function useStopwatchDIClientCheck() {
  const clientVersion = useSelector(selectZaClientVersion);
  const [clientSupportSw, setClientSupportSw] = useState('');
  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    if (
      (cv1 === 5 && cv2 === 15 && cv3 >= 10) ||
      (cv1 === 5 && cv2 > 15) ||
      cv1 >= 6
    ) {
      setClientSupportSw('swSupportedClient');
    } else if (
      cv1 === 5 &&
      ((cv2 === 13 && cv3 >= 10) || cv2 === 14 || (cv2 === 15 && cv3 < 10))
    ) {
      setClientSupportSw('swBackwardCompatibleClient');
    } else {
      setClientSupportSw('swUnsupportedClient');
    }
  }, [clientVersion]);
  return clientSupportSw;
}
export default useStopwatchDIClientCheck;
