import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducer';
import timerAudioReducer from '../utilities/MusicInTimer/reducer';

export const store = (preloadedState = {}) =>
  configureStore({
    reducer: {
      app: appReducer,
      audio: timerAudioReducer,
    },
    preloadedState,
  });

const activatedStore = store();

export default activatedStore;
