function PlayButtonIndicator() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_4991_12809'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          d='M0 1.3125C0 0.587626 0.587626 0 1.3125 0C2.03737 0 2.625 0.587626 2.625 1.3125V12.6875C2.625 13.4124 2.03737 14 1.3125 14C0.587626 14 0 13.4124 0 12.6875V1.3125Z'
          fill='#000001'
        />
        <path
          d='M11.375 5.6875C11.375 4.96263 11.9626 4.375 12.6875 4.375C13.4124 4.375 14 4.96263 14 5.6875V12.6875C14 13.4124 13.4124 14 12.6875 14C11.9626 14 11.375 13.4124 11.375 12.6875V5.6875Z'
          fill='#000001'
        />
        <path
          d='M6 8.0625C6 7.33763 6.58763 6.75 7.3125 6.75C8.03737 6.75 8.625 7.33763 8.625 8.0625V12.6875C8.625 13.4124 8.03737 14 7.3125 14C6.58763 14 6 13.4124 6 12.6875V8.0625Z'
          fill='#000001'
        />
      </mask>
      <g mask='url(#mask0_4991_12809)'>
        <rect width='18' height='18' fill='#4793F1' />
      </g>
    </svg>
  );
}

export default PlayButtonIndicator;
