import { useEffect } from 'react';

const useEnterEscKeyPress = ({
  isCurrentTab,
  openModal,
  startHandler,
  onContinueModal,
  isDynamicIndicatorSet,
  myDynamicIndicator,
  onCancelModal,
  resetWatch,
  timerTime,
}) => {
  useEffect(() => {
    if (isDynamicIndicatorSet && !myDynamicIndicator) return;

    const handleKeyPress = (event) => {
      if (isCurrentTab && event.key === 'Enter') {
        event.preventDefault();
        if (timerTime <= 0) {
          //Do Nothing, presents usage of enter key while negative timer is running.
        } else if (openModal) {
          // if "Enter key" is pressed and the modal is open, we proceed with "continue" button pressed behavior
          onContinueModal();
        } else {
          // if "Enter key is pressed" and the modal is not open, we trigger startLogic to open Modal;
          startHandler();
        }
      } else if (isCurrentTab && event.key === 'Escape') {
        event.preventDefault();
        if (openModal) {
          // if "Escape key" is pressed and the modal is open, we proceed with "cancel" button pressed behavior
          onCancelModal();
        } else {
          // if "Escape key is pressed" and the modal is not open, we trigger reset logic to open Modal;
          resetWatch();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isCurrentTab, openModal, onContinueModal, onCancelModal, timerTime]);
};

export default useEnterEscKeyPress;
