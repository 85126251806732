import { useSelector } from 'react-redux';
import {
  selectZaConfig,
  selectZaClientVersion,
  selectZaRunningContext,
} from '../../root/reducer';
import { useEffect, useState } from 'react';

/* This hook determines to show music options list or not on Desktop. 
   inMainClient: show music list on all client versions.
   inMeeting: clients >=5.16.5 support songChoice (to play music) and showNotification params in DI, so only show music list for clients >=5.16.5.
*/
function useMusicSupportCheckDesktopInMeeting() {
  const [
    isMusicSupportedDesktopInMeeting,
    setIsMusicSupportedDesktopInMeeting,
  ] = useState(false);
  const [
    isMusicSupportedDeskopInMainClient,
    setIsMusicSupportedDesktopInMainClient,
  ] = useState(false);
  const config = useSelector(selectZaConfig);
  const clientVersion = useSelector(selectZaClientVersion);
  const runningContext = useSelector(selectZaRunningContext);
  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    if (
      config?.product === 'desktop' &&
      ((cv1 === 5 && cv2 === 16 && cv3 >= 5) ||
        (cv1 === 5 && cv2 > 16) ||
        cv1 >= 6) &&
      runningContext === 'inMeeting'
    ) {
      setIsMusicSupportedDesktopInMeeting(true);
    } else {
      setIsMusicSupportedDesktopInMeeting(false);
    }

    if (config?.product === 'desktop' && runningContext === 'inMainClient') {
      setIsMusicSupportedDesktopInMainClient(true);
    } else {
      setIsMusicSupportedDesktopInMainClient(false);
    }
  }, [config, runningContext, clientVersion]);

  return [isMusicSupportedDesktopInMeeting, isMusicSupportedDeskopInMainClient];
}

export default useMusicSupportCheckDesktopInMeeting;
