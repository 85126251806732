import { createReducer } from '@reduxjs/toolkit';
import {
  setMusicSelectedOption,
  setTimerAudioState,
  setPreviousSelectedMusicOption,
  setCurrentPreviewMusic,
} from './actions';

/**
 * isTimerAudioOn: denotes of audio option is on or off in timer
 * selectedTimerMusicOption: denotes selected music option by the user. This state variable values are used to play music by timer and pass it to DI params.
 * previousSelectedMusicOption: stores selectedTimerMusicOption before user turns OFF timer audio. This variable helps bring back music option user selected when they turn back audio ON.
 *
 * id: 0 is default music alarm. Default music alarm is that dynamic indicator plays when no songChoice is passed and withSound is true.
 */
const initialState = {
  istimerAudioOn: true,
  selectedTimerMusicOption: {
    name: 'And the winner is...',
    id: 4,
  },
  previousSelectedMusicOption: {
    name: 'And the winner is...',
    id: 4,
  },
  currentPreviewMusic: 0,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setTimerAudioState, (state, action) => {
    state.istimerAudioOn = action.payload;
    return state;
  });
  builder.addCase(setMusicSelectedOption, (state, action) => {
    state.selectedTimerMusicOption.name = action.payload.name;
    state.selectedTimerMusicOption.id = action.payload.id;
    return state;
  });
  builder.addCase(setPreviousSelectedMusicOption, (state, action) => {
    state.previousSelectedMusicOption.name = action.payload.name;
    state.previousSelectedMusicOption.id = action.payload.id;
    return state;
  });
  builder.addCase(setCurrentPreviewMusic, (state, action) => {
    state.currentPreviewMusic = action.payload;
    return state;
  });
});

export function selectIsTimerAudioOn(state) {
  return state.audio.istimerAudioOn;
}

export function selectedTimerMusicOption(state) {
  return state.audio.selectedTimerMusicOption;
}

export function selectPreviousSelectedMusicOption(state) {
  return state.audio.previousSelectedMusicOption;
}

export function selectCurrentPreviewMusic(state) {
  return state.audio.currentPreviewMusic;
}
