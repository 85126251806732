import './AndroidNotice.css';
export default function AndroidNotice() {
  return (
    <div id='android_notice'>
      <div className='androidUpdateLogo'>
        <svg
          width='90'
          height='90'
          viewBox='0 0 90 90'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M44.9951 89.9903C69.8453 89.9903 89.9903 69.8453 89.9903 44.9951C89.9903 20.145 69.8453 0 44.9951 0C20.145 0 0 20.145 0 44.9951C0 69.8453 20.145 89.9903 44.9951 89.9903Z'
            fill='#414155'
          />
          <path
            d='M50.1899 24.7983C50.1899 21.9347 47.8685 19.6133 45.0049 19.6133C42.1412 19.6133 39.8198 21.9347 39.8198 24.7983V49.8884C39.8198 52.752 42.1412 55.0734 45.0049 55.0734C47.8685 55.0734 50.1899 52.752 50.1899 49.8884V24.7983Z'
            fill='#636986'
          />
          <path
            d='M44.9951 70.3866C47.8588 70.3866 50.1802 68.0652 50.1802 65.2016C50.1802 62.338 47.8588 60.0166 44.9951 60.0166C42.1315 60.0166 39.8101 62.338 39.8101 65.2016C39.8101 68.0652 42.1315 70.3866 44.9951 70.3866Z'
            fill='#636986'
          />
        </svg>
      </div>
      <div className='androidUpdateHeader'>Android Support Coming Soon</div>
      <div className='androidZoomLogo'>
        <img
          src={`${process.env.PUBLIC_URL}/Zoom-logo.png`}
          alt='timer logo'
        ></img>
      </div>
    </div>
  );
}
