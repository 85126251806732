import { Tooltip } from 'react-tooltip';
import useIsDesktopCheck from '../../hooks/useIsDesktopCheck';
import './CustomTooltip.css';

export default function CustomTooltip({ text, keyName, id }) {
  const isDesktop = useIsDesktopCheck();
  // only show tooltips when app is running on desktop
  return (
    <>
      {isDesktop && (
        <div className='customTooltipContainer'>
          <Tooltip
            id={id}
            place='bottom'
            className='customTooltip'
            classNameArrow='customTooltipArrow'
            offset={5}
          >
            <div>{text}</div>
            {keyName ? (
              <div className='tooltipKey'>{keyName}</div>
            ) : (
              <div className={`display:none`}></div>
            )}
          </Tooltip>
        </div>
      )}
    </>
  );
}
