import { useMemo } from 'react';

export default function LocalTime({
  timerTime,
  timerStatus,
  timerAudioToggleOn,
}) {
  const timeTimerWillEnd = useMemo(() => {
    const timeNow = new Date(Date.now() + timerTime * 1000);

    return timeNow.toLocaleTimeString('en', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    });
  }, [timerTime]);

  return (
    <div
      className={`Home__timer_localTime${
        timerStatus ? '' : ' localTime_paused'
      }`}
      style={{
        color: timerTime === 0 ? '#ED4D6A' : '#DDDDDD',
      }}
    >
      {timerAudioToggleOn ? (
        <svg
          width={15}
          height={14}
          viewBox='0 0 15 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <mask
            id='mask0_3738_394'
            style={{
              maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={0}
            y={0}
            width={15}
            height={14}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M4.65625 10.9377L7.76696 13.869C8.20026 14.1882 8.8125 13.8822 8.8125 13.344V0.656419C8.8125 0.118198 8.20026 -0.187812 7.76696 0.13146L4.65625 3.0627H3.125C1.67525 3.0627 0.5 4.23795 0.5 5.68769V8.3127C0.5 9.76244 1.67525 10.9377 3.125 10.9377H4.65625ZM7.9375 1.17303L5.25632 3.69951C5.09397 3.8525 4.87932 3.9377 4.65625 3.9377H3.125C2.1585 3.9377 1.375 4.7212 1.375 5.68769V8.3127C1.375 9.27919 2.1585 10.0627 3.125 10.0627H4.65625C4.87932 10.0627 5.09398 10.1479 5.25633 10.3009L7.9375 12.8274V1.17303Z'
              fill='#000001'
            />
            <path
              d='M10.2531 4.50337C10.424 4.33251 10.701 4.33251 10.8719 4.50337C12.2508 5.88233 12.2508 8.11808 10.8719 9.49705C10.701 9.6679 10.424 9.6679 10.2531 9.49705C10.0823 9.32619 10.0823 9.04919 10.2531 8.87833C11.2904 7.84107 11.2904 6.15934 10.2531 5.12209C10.0823 4.95123 10.0823 4.67422 10.2531 4.50337Z'
              fill='#000001'
            />
            <path
              d='M12.6219 2.75337C12.451 2.58251 12.174 2.58251 12.0031 2.75337C11.8323 2.92422 11.8323 3.20123 12.0031 3.37209C14.0069 5.37585 14.0069 8.6246 12.0031 10.6284C11.8323 10.7992 11.8323 11.0762 12.0031 11.2471C12.174 11.4179 12.451 11.4179 12.6219 11.2471C14.9673 8.90161 14.9673 5.09884 12.6219 2.75337Z'
              fill='#000001'
            />
          </mask>
          <g mask='url(#mask0_3738_394)'>
            <rect
              width={15}
              height={14}
              style={{
                fill: timerTime === 0 ? '#ED4D6A' : '#DDDDDD',
              }}
              transform='translate(0.5)'
            />
          </g>
        </svg>
      ) : (
        <svg
          width={15}
          height={14}
          viewBox='0 0 15 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <mask
            id='mask0_3738_659'
            style={{
              maskType: 'alpha',
            }}
            maskUnits='userSpaceOnUse'
            x={0}
            y={0}
            width={15}
            height={14}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.76696 13.869L4.65625 10.9377H3.125C1.67525 10.9377 0.5 9.76244 0.5 8.3127V5.68769C0.5 4.23795 1.67525 3.0627 3.125 3.0627H4.65625L7.76696 0.13146C8.20026 -0.187812 8.8125 0.118198 8.8125 0.656419V13.344C8.8125 13.8822 8.20026 14.1882 7.76696 13.869ZM5.25632 3.69951L7.9375 1.17303V12.8274L5.25633 10.3009C5.09398 10.1479 4.87932 10.0627 4.65625 10.0627H3.125C2.1585 10.0627 1.375 9.27919 1.375 8.3127V5.68769C1.375 4.7212 2.1585 3.9377 3.125 3.9377H4.65625C4.87932 3.9377 5.09397 3.8525 5.25632 3.69951Z'
              fill='#000001'
            />
            <path
              d='M10.8719 4.94085C10.701 4.77 10.424 4.77 10.2531 4.94085C10.0823 5.11171 10.0823 5.38872 10.2531 5.55957L11.6938 7.00021L10.2531 8.44085C10.0823 8.61171 10.0823 8.88872 10.2531 9.05957C10.424 9.23043 10.701 9.23043 10.8719 9.05957L12.3125 7.61893L13.7531 9.05957C13.924 9.23043 14.201 9.23043 14.3719 9.05957C14.5427 8.88872 14.5427 8.61171 14.3719 8.44085L12.9312 7.00021L14.3719 5.55957C14.5427 5.38872 14.5427 5.11171 14.3719 4.94085C14.201 4.77 13.924 4.77 13.7531 4.94085L12.3125 6.3815L10.8719 4.94085Z'
              fill='#000001'
            />
          </mask>
          <g mask='url(#mask0_3738_659)'>
            <rect
              width={15}
              height={14}
              style={{
                fill: timerTime === 0 ? '#ED4D6A' : '#DDDDDD',
              }}
              transform='translate(0.5)'
            />
          </g>
        </svg>
      )}

      {timeTimerWillEnd}
    </div>
  );
}
