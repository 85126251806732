import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setMusicSelectedOption,
  setPreviousSelectedMusicOption,
} from '../utilities/MusicInTimer/actions';

function useStorePreferredMusic() {
  const dispatch = useDispatch();
  let preferredMusic = null;
  useEffect(() => {
    try {
      preferredMusic = JSON.parse(
        window.localStorage.getItem('preferredMusic')
      );
    } catch (error) {}

    if (preferredMusic && preferredMusic.name) {
      dispatch(
        setMusicSelectedOption({
          name: preferredMusic.name,
          id: preferredMusic.id,
        })
      );

      dispatch(
        setPreviousSelectedMusicOption({
          name: preferredMusic.name,
          id: preferredMusic.id,
        })
      );
    } else {
      dispatch(
        setMusicSelectedOption({
          name: 'And the winner is...',
          id: 4,
        })
      );

      dispatch(
        setPreviousSelectedMusicOption({
          name: 'And the winner is...',
          id: 4,
        })
      );
    }
  }, []);
}

export default useStorePreferredMusic;
