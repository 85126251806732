import { useSelector } from 'react-redux';
import { selectZaClientVersion, selectZaConfig } from '../root/reducer';
import { useEffect, useState } from 'react';

function useVideoSettings() {
  const clientVersion = useSelector(selectZaClientVersion);
  const [clientSupportVideoSettings, setClientSupportVideoSettings] =
    useState(false);
  const videoSettingsAPIs = [
    'onVideoSettingsChange',
    'getVideoSettings',
    'setVideoSettings',
    'setVideoState',
  ];

  const config = useSelector(selectZaConfig);
  const unsupportedApis = config?.unsupportedApis || null;
  const videoSettingsAPISupport =
    unsupportedApis === null
      ? false
      : videoSettingsAPIs.every((api) => !unsupportedApis.includes(api));

  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    if (
      ((cv1 === 5 && cv2 === 17 && cv3 >= 0) ||
        (cv1 >= 5 && cv2 > 17) ||
        cv1 >= 6) &&
      videoSettingsAPISupport &&
      config?.product === 'desktop'
    ) {
      setClientSupportVideoSettings(true);
    }
  }, [clientVersion, config?.product]);

  return clientSupportVideoSettings;
}
export default useVideoSettings;
