import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectZaVideoDimensions } from '../root/reducer';

// this hook adjusts size based on user's video dimensions width from config and onMyMediaChange.
function useVideoDimensions() {
  const VIDEO_SIZE = 320;
  const videoDimensions = useSelector(selectZaVideoDimensions);
  const [width, setWidth] = useState();
  const [isVideoDimensionsChange, setIsVideoDimensionsChange] = useState(false);
  const [isVideoTurnedOn, setIsVideoTurnedOn] = useState(false);

  const getSize = useCallback(
    (dimensions) => {
      return dimensions && dimensions.width
        ? Math.round(dimensions.width * 0.25)
        : VIDEO_SIZE;
    },
    [videoDimensions]
  );
  const [size, setSize] = useState(getSize(videoDimensions));

  useEffect(() => {
    !videoDimensions ? setIsVideoTurnedOn(false) : setIsVideoTurnedOn(true);
    setSize(getSize(videoDimensions));
    setWidth(videoDimensions?.width);
  }, [videoDimensions]);

  const updateSizeOnMediaChange = useCallback((event) => {
    setWidth(event.media.video?.width);
    setIsVideoDimensionsChange(true);
    if (event.media.video.width) {
      setSize(Math.round(event.media.video.width * 0.25));
    } else {
      setSize(VIDEO_SIZE);
    }

    setIsVideoTurnedOn(event.media?.video?.state);
  }, []);

  /*  
    whenever user's media change, update the size so that VF for timer and SW render as per video dimensions.
    When timer and SW are not running and component is unmounted, we still have to detect media changes and pass updated state to both timer and SW.
  */
  useEffect(() => {
    zoomSdk.addEventListener('onMyMediaChange', (myMediaEvent) => {
      updateSizeOnMediaChange(myMediaEvent);
    });
    return () => {
      zoomSdk.removeEventListener('onMyMediaChange', updateSizeOnMediaChange);
    };
  }, []);

  return [size, isVideoDimensionsChange, width, isVideoTurnedOn];
}

export default useVideoDimensions;
