import { formatTime } from '../utils';
import { useEffect } from 'react';
import CustomTooltip from '../utilities/CustomTooltip/CustomTooltip';

export function ControlButton({
  timerTime,
  startTimer,
  timerStatus,
  countdownStatus,
  disabled,
  isMacOS,
}) {
  return (
    <div className='Countdown__control_button'>
      <button
        data-tooltip-id='timer-controlbutton-tooltip'
        data-tooltip-delay-show={300}
        aria-label={
          countdownStatus ? (timerStatus ? 'Pause' : 'Resume') : 'Start'
        }
        onClick={startTimer}
        className='Home__time_button'
        id='control_button'
        disabled={disabled}
      >
        {timerStatus ? 'Pause' : 'Resume'}
      </button>
      <CustomTooltip
        text='Keyboard shortcut'
        keyName={isMacOS ? 'return' : 'enter'}
        id='timer-controlbutton-tooltip'
      />
    </div>
  );
}
export function SWControlButton({
  stopwatchIsOn,
  stopwatchPaused,
  swController,
  isMacOS,
  disabled,
}) {
  return (
    <div className='Sw__control_button'>
      <button
        aria-label={
          stopwatchIsOn ? (stopwatchPaused ? 'Resume' : 'Pause') : 'Start'
        }
        data-tooltip-id='sw-controlbutton-tooltip'
        data-tooltip-delay-show={300}
        className='Sw__time_button'
        id='control_button'
        onClick={swController}
        disabled={disabled}
      >
        {stopwatchIsOn ? (stopwatchPaused ? 'Resume' : 'Pause') : 'Start'}
      </button>
      <CustomTooltip
        text='Keyboard shortcut'
        keyName={isMacOS ? 'return' : 'enter'}
        id='sw-controlbutton-tooltip'
      />
    </div>
  );
}

export function UpButton({
  className,
  label,
  tabindex,
  onClickFunction,
  onMouseDown,
  onMouseUp,
  onContextMenu,
  id,
}) {
  return (
    <button
      className={className}
      aria-label={label}
      tabIndex={tabindex}
      onClick={onClickFunction}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onContextMenu={onContextMenu}
    >
      <svg
        width={14}
        height={14}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id={id}
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={-1}
          y={3}
          width={15}
          height={8}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.5042 10.6801C12.8462 11.0215 13.4009 11.0216 13.7431 10.6803C14.0852 10.3391 14.0853 9.78572 13.7433 9.44435L7.70893 3.42182C7.68257 3.38588 7.65312 3.35149 7.62057 3.31903C7.28624 2.98553 6.74918 2.97819 6.40591 3.29679C6.39533 3.30637 6.38492 3.31625 6.37471 3.32644C6.36755 3.33358 6.36054 3.34081 6.35368 3.34813L0.25422 9.44428C-0.0875815 9.78589 -0.0873095 10.3395 0.254829 10.6808C0.596966 11.022 1.15141 11.0218 1.49321 10.6802L6.99481 5.18155L12.5042 10.6801Z'
            fill='#000001'
          />
        </mask>
        <g mask={`url(#${id})`}>
          <rect width={14} height={14} fill='#F7F9FA' />
        </g>
      </svg>
    </button>
  );
}

export function DownButton({
  className,
  label,
  tabindex,
  onClickFunction,
  onMouseDown,
  onMouseUp,
  id,
  onContextMenu,
}) {
  return (
    <button
      className={className}
      aria-label={label}
      tabIndex={tabindex}
      onClick={onClickFunction}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onContextMenu={onContextMenu}
    >
      <svg
        width={14}
        height={14}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id={id}
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={-1}
          y={3}
          width={15}
          height={8}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.5042 3.31891C12.8462 2.97753 13.4009 2.97743 13.7431 3.31867C14.0852 3.65991 14.0853 4.21328 13.7433 4.55465L7.70893 10.5772C7.68257 10.6131 7.65312 10.6475 7.62057 10.68C7.28624 11.0135 6.74918 11.0208 6.40591 10.7022C6.39533 10.6926 6.38492 10.6827 6.37471 10.6726C6.36755 10.6654 6.36054 10.6582 6.35368 10.6509L0.25422 4.55472C-0.0875815 4.2131 -0.0873095 3.65951 0.254829 3.31823C0.596966 2.97695 1.15141 2.97722 1.49321 3.31884L6.99481 8.81745L12.5042 3.31891Z'
            fill='#000001'
          />
        </mask>
        <g mask={`url(#${id})`}>
          <rect width={14} height={14} fill='#F7F9FA' />
        </g>
      </svg>
    </button>
  );
}

export function TimeButtons({
  format,
  setStartTime,
  setPresetTime,
  setTimerTimeWithConstraint,
}) {
  async function onClickFunction(time) {
    await setTimerTimeWithConstraint(time);
    await setStartTime(time);
    setPresetTime(true);
  }
  const presetTimesArray = [60, 180, 300, 600];
  return (
    <div className='Home__presetTime'>
      {presetTimesArray.map((time) => (
        <button
          aria-label={`Preset Time to ${time} Seconds`}
          className={`Home__time_button ${!time && 'hide'}`}
          key={time}
          onClick={() => onClickFunction(time)}
        >
          {format
            ? `${formatTime(time).hours}:${formatTime(time).minutes}:${
                formatTime(time).seconds
              }`
            : `${time / 60}`}
          {!format && <span className='preset_quantity'>min</span>}
        </button>
      ))}
    </div>
  );
}
export function DisplaySWForOthersButton({
  displayOnVideo,
  onClickFunction,
  text,
  disabled = false,
}) {
  useEffect(() => {
    document.getElementById('swVideoToggle').checked = displayOnVideo;
  }, [displayOnVideo]);
  return (
    <>
      <div
        className='Home__timer_video_button'
        style={{
          visibility: disabled ? 'hidden' : 'inherit',
        }}
      >
        <div className='timer_video_button_text'>
          <span className={`${disabled ? 'disabled' : ''}`}>{text}</span>
          <button
            className='tooltip'
            type='button'
            aria-describedby='tooltip-description'
            aria-labelledby='tooltip'
          >
            <svg
              className='info_icon'
              width='16'
              height='16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
            >
              <mask
                id='toolTipMask'
                style={{ maskType: 'alpha' }}
                maskUnits='userSpaceOnUse'
                x='1'
                y='1'
                width='14'
                height='14'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm-.75 10.25a.75.75 0 0 0 1.5 0v-4a.75.75 0 0 0-1.5 0v4ZM8 4a.75.75 0 1 0 0 1.5A.75.75 0 0 0 8 4Z'
                  fill='#000001'
                />
              </mask>
              <g mask='url(#toolTipMask)'>
                <path fill='#F7F9FA' d='M0 0h16v16H0z' />
              </g>
            </svg>
          </button>
          <div className='info_box' role='tooltip' id='tooltip-description'>
            <p>For the best experience:</p>
            <ol>
              <li>Keep "Mirror My Video" off in Video Settings</li>
              <li>Do not "Stop Video"</li>
            </ol>
          </div>
        </div>
        <label className='switch'>
          <input
            disabled={disabled}
            className={`${disabled ? 'disabled' : ''}`}
            id='swVideoToggle'
            type='checkbox'
            aria-label={
              displayOnVideo
                ? 'Display Timer for Others, checkbox checked'
                : 'Display Timer for Others, checkbox unchecked'
            }
            onClick={onClickFunction}
            defaultChecked={displayOnVideo}
          />
          <span className={`slider ${disabled ? 'disabled' : ''}`}></span>
        </label>
      </div>
    </>
  );
}
export function DisplayTimerForOthersButton({
  displayOnVideo,
  onClickFunction,
  text,
  disabled = false,
}) {
  useEffect(() => {
    document.getElementById('timerVideoToggle').checked = displayOnVideo;
  }, [displayOnVideo]);
  return (
    <>
      <div
        className='Home__timer_video_button'
        style={{
          visibility: disabled ? 'hidden' : 'inherit',
        }}
      >
        <div className='timer_video_button_text'>
          <span className={`${disabled ? 'disabled' : ''}`}>{text}</span>
          <button
            className='tooltip'
            type='button'
            aria-describedby='tooltip-description'
            aria-labelledby='tooltip'
          >
            <svg
              className='info_icon'
              width='16'
              height='16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              aria-hidden='true'
            >
              <mask
                id='toolTipMask'
                style={{ maskType: 'alpha' }}
                maskUnits='userSpaceOnUse'
                x='1'
                y='1'
                width='14'
                height='14'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm-.75 10.25a.75.75 0 0 0 1.5 0v-4a.75.75 0 0 0-1.5 0v4ZM8 4a.75.75 0 1 0 0 1.5A.75.75 0 0 0 8 4Z'
                  fill='#000001'
                />
              </mask>
              <g mask='url(#toolTipMask)'>
                <path fill='#F7F9FA' d='M0 0h16v16H0z' />
              </g>
            </svg>
          </button>
          <div className='info_box' role='tooltip' id='tooltip-description'>
            <p>For the best experience:</p>
            <ol>
              <li>Keep "Mirror My Video" off in Video Settings</li>
              <li>Do not "Stop Video"</li>
            </ol>
          </div>
        </div>
        <label className='switch'>
          <input
            disabled={disabled}
            className={`${disabled ? 'disabled' : ''}`}
            id='timerVideoToggle'
            type='checkbox'
            aria-label={
              displayOnVideo
                ? 'Display Timer for Others, checkbox checked'
                : 'Display Timer for Others, checkbox unchecked'
            }
            onClick={onClickFunction}
            defaultChecked={displayOnVideo}
          />
          <span className={`slider ${disabled ? 'disabled' : ''}`}></span>
        </label>
      </div>
    </>
  );
}

export function BackButton({ onClickFunction }) {
  return (
    <button className='back-button' onClick={onClickFunction}>
      <svg
        width={40}
        height={40}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width={40} height={40} rx={10} fill='#252A30' />
        <mask
          id='a'
          style={{
            maskType: 'alpha',
          }}
          maskUnits='userSpaceOnUse'
          x={15}
          y={11}
          width={10}
          height={18}
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24.205 13.708c.39-.39.39-1.025 0-1.416a.997.997 0 0 0-1.412 0l-6.883 6.896a1.002 1.002 0 0 0-.084 1.549l6.967 6.971a.998.998 0 0 0 1.413 0c.39-.392.39-1.025-.001-1.416l-6.284-6.287 6.284-6.297Z'
            fill='#000001'
          />
        </mask>
        <g mask='url(#a)'>
          <path fill='#F7F9FA' d='M12 12h16v16H12z' />
        </g>
      </svg>
    </button>
  );
}
