/* used in music options list dropdown*/
function Chevron() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_4251_164'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='4'
        width='8'
        height='6'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.70502 4.92935C6.69883 4.93487 6.69276 4.9406 6.68682 4.94653C6.68182 4.95152 6.67697 4.9566 6.67227 4.96177L3.19148 8.44251C3.02063 8.61336 3.02084 8.89016 3.19195 9.06076C3.36306 9.23135 3.64028 9.23114 3.81114 9.06029L6.99749 5.87398L10.1918 9.05822C10.3629 9.22881 10.6402 9.2287 10.8112 9.05798C10.9822 8.88726 10.9821 8.61057 10.811 8.43999L7.37362 5.01343C7.35675 4.98706 7.33677 4.9621 7.31368 4.93908C7.14604 4.77195 6.87656 4.76876 6.70502 4.92935Z'
          fill='#000001'
        />
      </mask>
      <g mask='url(#mask0_4251_164)'>
        <rect width='14' height='14' fill='#F7F9FA' />
      </g>
    </svg>
  );
}

export default Chevron;
