/* globals zoomSdk */
import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectZaClientVersion } from '../root/reducer';
import { determineTimerColor } from '../utils';
import { LINE_WIDTH_FOR_1280P } from './Constants';
import VideoSettings from './VideoSettings';

export default function Canvas({
  hours,
  minutes,
  seconds,
  startTime,
  timerTime,
  watchOnVideo,
  watchType,
  vfOnVideo,
  setVfOnVideo,
  size,
  width,
  isVideoDimensionsChange,
  isVideoTurnedOn,
}) {
  const [showError, setShowError] = useState(false);
  const [persistenceCheck, setPersistenceCheck] = useState(false);
  const clientVersion = useSelector(selectZaClientVersion);
  const timeHeight = useRef(0);
  const [scale, setScale] = useState(
    window.devicePixelRatio === 1 ? 2 : window.devicePixelRatio
  );
  const fontSettings =
    "px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

  const closeErrorToast = useCallback(() => {
    document
      .getElementsByClassName('error_toast')[0]
      .classList.add('error_toast_quick_exit');
  }, []);
  const [lineWidth, setLineWidth] = useState(12);

  // This effect updates lineWidth used by timer VF based on width from video dimensions
  useEffect(() => {
    const ratio = width / 1280;
    setLineWidth(Math.floor(LINE_WIDTH_FOR_1280P * ratio));
  }, [width]);

  /**
   * This effect adds an event listener for devicePixelRatio change and sets the scale accordingly.
   */
  useEffect(() => {
    try {
      const handleResize = () => {
        setScale(window.devicePixelRatio === 1 ? 2 : window.devicePixelRatio);
      };
      const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
      const media = matchMedia(mqString);
      media.addEventListener('change', handleResize);

      return () => {
        media.removeEventListener('change', handleResize);
      };
    } catch (error) {
      /*this code might very occassionally throw error on older macOS operating systems saying media.addEventListener
      is not a function.*/
    }
  }, []);

  useEffect(() => {
    if (parseInt(clientVersion.split('.').join('')) >= 51160000) {
      setPersistenceCheck(true);
    }
  }, [clientVersion]);

  useEffect(() => {
    // when video dimensions change recalculate the timeHeight -- this helps digits align vertically in center
    if (isVideoDimensionsChange || timeHeight.current === 0) {
      let c = document.getElementById('canvas_height');
      let ctx = c.getContext('2d');
      ctx.font = '600 ' + size * 0.16 * scale + fontSettings;

      timeHeight.current =
        ctx.measureText('00:00').actualBoundingBoxAscent +
        ctx.measureText('00:00').actualBoundingBoxDescent;
    }

    let c = document.getElementById('canvas_countdown');
    let ctx = c.getContext('2d');

    c.style.width = size + 'px';
    c.style.height = size + 'px';

    c.width = Math.floor(size * scale);
    c.height = Math.floor(size * scale);

    /* Outer Circle (semi-transparent part)
    ctx.beginPath();
    ctx.arc(c.width / 2, c.height / 2, (size / 2 - 5) * scale, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(82, 82, 128, 0.018)';
    ctx.fill();
     */

    /* Outer Circle (faded blue part)
    ctx.beginPath();
    ctx.arc(c.width / 2, c.height / 2, (size / 2 - 29) * scale, 0, 2 * Math.PI);
    ctx.strokeStyle = 'rgba(92, 127, 236, 0.18)';
    ctx.lineWidth = 20;
    ctx.stroke();
    */

    // Background For Circular Progress Bar (white part)
    ctx.beginPath();
    ctx.arc(
      c.width / 2,
      c.height / 2,
      ((size * 0.85) / 2) * scale,
      0,
      2 * Math.PI
    );
    ctx.strokeStyle = '#444444';
    ctx.lineWidth = lineWidth;
    ctx.stroke();

    // Circular Progress Bar (blue part)
    ctx.beginPath();
    ctx.arc(
      c.width / 2,
      c.height / 2,
      ((size * 0.85) / 2) * scale,
      4.71239,
      timerTime <= 0
        ? (2 * Math.PI * startTime) / startTime + 4.71239
        : (2 * Math.PI * timerTime) / startTime + 4.71239
    );
    if (watchType === 'stopwatch') {
      ctx.strokeStyle = '#0E72ED'; // if it's a stopwatch, show blue color.
    } else {
      ctx.strokeStyle = determineTimerColor(timerTime, startTime);
    }
    ctx.lineWidth = lineWidth;
    ctx.lineCap = 'round';
    ctx.stroke();

    // Inner Background (white part)
    ctx.beginPath();
    ctx.arc(
      c.width / 2,
      c.height / 2,
      ((size * 0.83) / 2) * scale,
      0,
      2 * Math.PI
    );
    if (timerTime <= 0) {
      ctx.fillStyle = '#43131C';
    } else {
      ctx.fillStyle = '#000000';
    }
    ctx.fill();

    // Inner Background (gray part)
    ctx.beginPath();
    ctx.arc(
      c.width / 2,
      c.height / 2,
      ((size * 0.83) / 2) * scale,
      0,
      2 * Math.PI
    );
    ctx.fillStyle = 'rgba(82, 82, 128, 0.2)';
    ctx.fill();

    // Timer Countdown Time
    ctx.fillStyle = '#ffffff';
    ctx.font = '600 ' + size * 0.16 * scale + fontSettings;
    let hoursWidth = Math.abs(hours) > 0 ? ctx.measureText(hours).width : 0;
    let hoursColonWidth = Math.abs(hours) > 0 ? ctx.measureText(':').width : 0;
    let minutesWidth = ctx.measureText(minutes).width;
    let minutesColonWidth = ctx.measureText(':').width;
    let secondsWidth = ctx.measureText(seconds).width;
    let baseWidth =
      c.width -
      hoursWidth -
      hoursColonWidth -
      minutesWidth -
      minutesColonWidth -
      secondsWidth;

    if (Math.abs(hours) > 0) {
      ctx.fillText(
        `${hours}`,
        baseWidth / 2,
        (c.height + timeHeight.current) / 2
      );
      ctx.fillText(
        ':',
        baseWidth / 2 + hoursWidth,
        (c.height + timeHeight.current) / 2 - 5
      );
    }

    ctx.fillText(
      `${
        timerTime < 0 && Math.abs(hours) > 0 ? minutes.substring(1) : minutes
      }`,
      baseWidth / 2 + hoursWidth + hoursColonWidth,
      (c.height + timeHeight.current) / 2
    );
    ctx.fillText(
      ':',
      baseWidth / 2 + hoursWidth + hoursColonWidth + minutesWidth,
      (c.height + timeHeight.current) / 2 - 5
    );

    ctx.fillText(
      `${timerTime < 0 ? seconds.substring(1) : seconds}`,
      baseWidth / 2 +
        hoursWidth +
        hoursColonWidth +
        minutesWidth +
        minutesColonWidth,
      (c.height + timeHeight.current) / 2
    );

    return () => {
      ctx.clearRect(0, 0, c.width, c.height);
    };
  }, [
    size,
    scale,
    startTime,
    timerTime,
    hours,
    minutes,
    seconds,
    width,
    lineWidth,
    timeHeight,
    isVideoDimensionsChange,
  ]);

  useEffect(() => {
    let c = document.getElementById('canvas_countdown');
    let smallCanvas = document.getElementById('canvas_small');
    let smallContext = smallCanvas.getContext('2d');

    if (watchOnVideo) {
      smallCanvas.style.width = size + 'px';
      smallCanvas.style.height = size + 'px';

      smallCanvas.width = Math.floor(size * scale);
      smallCanvas.height = Math.floor(size * scale);

      smallContext.scale(0.5, 0.5);
      smallContext.drawImage(c, 0, 0);

      const canvasImageData = smallContext.getImageData(
        0,
        0,
        size * scale,
        size * scale
      );

      async function setVF() {
        try {
          let setVFResponse;
          if (persistenceCheck) {
            setVFResponse = await zoomSdk.callZoomApi('setVirtualForeground', {
              imageData: canvasImageData,
              persistence: 'app',
            });
          } else {
            setVFResponse = await zoomSdk.callZoomApi('setVirtualForeground', {
              imageData: canvasImageData,
            });
          }
          setVfOnVideo(true);
          setShowError(false);
          return setVFResponse;
        } catch (error) {
          if (
            parseInt(hours) > 0 ||
            parseInt(minutes) > 0 ||
            parseInt(seconds) > 0
          ) {
            setShowError(true);
          }
        }
      }
      setVF();
    } else {
      if (vfOnVideo) {
        zoomSdk.callZoomApi('removeVirtualForeground').catch((error) => {
          /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs do it function but still throws an error saying something went wrong.
          Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
        });
        setVfOnVideo(false);
      }
    }
    return () => {
      smallContext.clearRect(0, 0, c.width, c.height);
    };
  }, [
    size,
    scale,
    hours,
    minutes,
    seconds,
    watchOnVideo,
    closeErrorToast,
    vfOnVideo,
    persistenceCheck,
  ]);

  return (
    <>
      <VideoSettings
        showError={showError || !isVideoTurnedOn}
        watchType={watchType}
        showBanner={watchOnVideo}
      />
      <canvas hidden id='canvas_countdown'></canvas>
      <canvas hidden id='canvas_small'></canvas>
      <canvas hidden id='canvas_height'></canvas>
    </>
  );
}
