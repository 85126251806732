import { createReducer } from '@reduxjs/toolkit';
import { zaConfig, cancelZaConfig } from './actions';

const initialState = {
  zaConfig: null,
  zaRunningContext: '',
  zaClientVersion: '',
  zaVideoDimensions: null,
  zaUserAgent: null,
  zaConfigPending: false,
  zaConfigError: null,
  zaConfigScheduled: false,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(zaConfig.fulfilled, (state, action) => {
      state.zaConfig = action.payload;
      state.zaRunningContext = action.payload.runningContext;
      state.zaClientVersion = action.payload.clientVersion;
      state.zaUserAgent = action.payload.userAgent;
      state.zaVideoDimensions =
        action.payload.runningContext !== 'inMainClient'
          ? action.payload.media?.video
          : '';
      state.zaConfigPending = false;
      state.zaConfigError = null;
      state.zaConfigScheduled = true;
      return state;
    })
    .addCase(zaConfig.pending, (state, action) => {
      state.zaConfigPending = true;
      state.zaConfigScheduled = false;
      return state;
    })
    .addCase(zaConfig.rejected, (state, action) => {
      state.zaConfigPending = false;
      state.zaConfigError = action.error;
      state.zaConfigScheduled = false;
      return state;
    })
    .addCase(cancelZaConfig, (state, action) => {
      state.zaConfigScheduled = false;
      return state;
    })
    .addDefaultCase((state, action) => {
      return state;
    });
});

export function selectIsZaConfigPending(state) {
  return state.app.zaConfigPending;
}

export function selectZaConfigError(state) {
  return state.app.zaConfigError;
}

export function selectZaConfig(state) {
  return state.app.zaConfig;
}

export function selectIsZaConfigScheduled(state) {
  return state.app.zaConfigScheduled;
}

export function selectZaRunningContext(state) {
  return state.app.zaRunningContext;
}

export function selectZaClientVersion(state) {
  return state.app.zaClientVersion;
}

export function selectZaVideoDimensions(state) {
  return state.app.zaVideoDimensions;
}

export function selectZaUserAgent(state) {
  return state.app.zaUserAgent;
}
