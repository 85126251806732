export const musicList = [
  {
    id: 4,
    name: 'And the winner is...',
  },
  {
    id: 1,
    name: 'Times Up!',
  },
  {
    id: 2,
    name: 'Vegas',
  },
  {
    id: 3,
    name: 'Pencils Down',
  },
  {
    id: 5,
    name: 'Beethoven Begins',
  },
  {
    id: 6,
    name: 'On The LaunchPad',
  },
  {
    id: 7,
    name: 'We Made it',
  },
  {
    id: 8,
    name: 'Vamos',
  },
  {
    id: 9,
    name: 'Golden Ticket',
  },
  {
    id: 10,
    name: 'Ooh La La',
  },
];
