import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { zaConfig, cancelZaConfig } from './actions';
import Home from '../home/Home';
import './App.css';
import NavHeader from '../nav-header/NavHeader';
import { HOME_TAB } from '../nav-header/constants';
import Stopwatch from '../stopwatch/Stopwatch';
import Splash from '../home/Splash';
import useDynamicIndicatorClientCheck from '../hooks/useDynamicIndicatorClientCheck';
import useSupportRunningContext from '../hooks/useSupportRunningContext';
import ClientCompatibilityNotice from '../utilities/ClientCompatibilityNotice';
import { BASE_TIMER_TIME } from '../utilities/Constants';
import useVideoDimensions from '../hooks/useVideoDimensions';
import useWithSound from '../hooks/useWithSound';
import useStopwatchDIClientCheck from '../hooks/useStopwatchDIClientCheck';
import useMobileAndroidCheck from '../hooks/useMobileAndroidCheck';
import AndroidNotice from '../utilities/Android Notice/AndroidNotice';

export default function App() {
  const [screenName, setScreenName] = useState(null);
  const [currentTab, setCurrentTab] = useState(HOME_TAB);
  const [timerIsRunning, setTimerIsRunning] = useState(false);
  const [timerVideoToggleOn, setTimerVideoToggleOn] = useState(true);
  const [stopwatchIsOn, setStopwatchIsOn] = useState(false);
  const [stopwatchPaused, setStopwatchPaused] = useState(false);
  const [stopwatchVideoToggleOn, setStopwatchVideoToggleOn] = useState(true);
  const [dynamicTimerOnEvent, setDynamicTimerOnEvent] = useState(null);
  const [dynamicStopwatchOnEvent, setDynamicStopwatchOnEvent] = useState(null);
  const [dynamicTimerRemoveEvent, setDynamicTimerRemoveEvent] = useState(null);
  const [dynamicStopwatchRemoveEvent, setDynamicStopwatchRemoveEvent] =
    useState(null);
  const [timerTime, setTimerTime] = useState(BASE_TIMER_TIME); // The resume and Timer time can be combined in one current Time.
  const [userContext, setUserContext] = useState(null);
  const [myDynamicIndicator, setMyDynamicIndicator] = useState(false);
  const [isTimerDISet, setIsTimerDISet] = useState(false);
  const [isStopwatchDISet, setIsStopwatchDISet] = useState(false);
  const [countdownStatus, setCountdownStatus] = useState(false);
  const [backwardCompatibleView, setBackwardCompatibleView] = useState(false);
  const [swUpdateClientView, setSwUpdateClientView] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [meetingTimerDisabled, setMeetingTimerDisabled] = useState(false);
  const [timerAudioSettingFromDI, setTimerAudioSettingFromDI] =
    useState('notsupported');
  const supportRunningContext = useSupportRunningContext();
  const dynamicIndicatorClientCheck = useDynamicIndicatorClientCheck();
  const [size, isVideoDimensionsChange, width, isVideoTurnedOn] =
    useVideoDimensions();
  const swDIClientCheck = useStopwatchDIClientCheck();
  const clientSupportWithSound = useWithSound();
  const dispatch = useDispatch();
  const [stopwatchTime, setStopwatchTime] = useState(0);
  const isMobileAndroid = useMobileAndroidCheck();
  const [clientSupportNegativeAlarm, setClientSupportNegativeAlarm] =
    useState('');

  /**
   * This Effect is used to call the SDK APIs config and getUserContext.
   */
  useEffect(() => {
    async function getContext() {
      await dispatch(zaConfig());
      try {
        const runningContext = await zoomSdk.getRunningContext();
        if (runningContext !== 'inMainClient') {
          const userContextData = await zoomSdk.getUserContext();
          setUserContext(userContextData);
        }
      } catch (error) {
        setSomethingWentWrong(true);
      }
    }
    getContext();
    return () => {
      dispatch(cancelZaConfig());
    };
  }, [dispatch]);

  /**
   * This effect sets the value of setSomethingWentWrong to false in 5 seconds
   * everytime after an error toast is shown.
   */
  useEffect(() => {
    if (somethingWentWrong) {
      setTimeout(() => {
        setSomethingWentWrong(false);
      }, 6000);
    }
  }, [somethingWentWrong]);
  /**
   * This effect sets the value of setMeetingTimerDisabled to false in 5 seconds
   * everytime after an error toast is shown.
   */
  useEffect(() => {
    if (meetingTimerDisabled) {
      setTimeout(() => {
        setMeetingTimerDisabled(false);
      }, 6000);
    }
  }, [meetingTimerDisabled]);
  useEffect(() => {
    const timeId = setTimeout(() => {
      document.getElementById('App').style.visibility = 'visible';
    }, 1500);

    return () => {
      dispatch(clearTimeout(timeId));
    };
  }, []);

  /**
   *This Effect works when the user starts the App for the first time or refreshers it.
   * The Apps calls the getDynamicIndicator API and checks for an existing dynamic Indicator.
   * If there exists a dynamic indicator, the app starts functioning in a shared experience.
   */
  useEffect(() => {
    if (
      userContext !== null &&
      supportRunningContext &&
      (dynamicIndicatorClientCheck === 'dISupportedClient' ||
        dynamicIndicatorClientCheck === 'dIBackwardCompatibleClient')
    ) {
      zoomSdk
        .callZoomApi('getDynamicIndicator')
        .then(async (data) => {
          setScreenName(data?.screenName);
          data.timer.countNegativeAfterAlarm === true
            ? setClientSupportNegativeAlarm(true)
            : setClientSupportNegativeAlarm(false);
          if (dynamicIndicatorClientCheck === 'dISupportedClient') {
            if (userContext.participantUUID === data.participantUUID) {
              setMyDynamicIndicator(true);
            } else {
              setMyDynamicIndicator(false);
              setTimerVideoToggleOn(false);
              setStopwatchVideoToggleOn(false);
            }
            /**
             * Down direction runs for Timer and Up direction runs for Stopwatch.
             */
            if (data.timer.direction === 'down') {
              setIsTimerDISet(true);
              setCurrentTab('home');
              const eventTime = (data.timer.start / 1000).toString();
              if (userContext.participantUUID === data.participantUUID) {
                await window.localStorage.setItem('LastSetTime', eventTime);
              } else {
                await window.localStorage.setItem(
                  'PublisherSetTime',
                  eventTime
                );
              }
              setTimerTime(data.timer.current / 1000);
              if (data.timer.action === 'start') {
                setDynamicTimerOnEvent(true);
              } else if (data.timer.action === 'pause') {
                setCountdownStatus(true);
                setTimerIsRunning(true);
                setDynamicTimerOnEvent(false);
              } else if (data.timer.action === 'resume') {
                setCountdownStatus(true);
                setTimerIsRunning(false);
                setDynamicTimerOnEvent(true);
              }
            } else if (data.timer.direction === 'up') {
              if (swDIClientCheck === 'swSupportedClient') {
                setIsStopwatchDISet(true);
                setCurrentTab('stopwatch');
                setStopwatchTime(data.timer.current);
                if (data.timer.action === 'start') {
                  setDynamicStopwatchOnEvent(true);
                } else if (data.timer.action === 'pause') {
                  setStopwatchIsOn(true);
                  setDynamicStopwatchOnEvent(false);
                } else if (data.timer.action === 'resume') {
                  setStopwatchIsOn(true);
                  setStopwatchPaused(true);
                  setDynamicStopwatchOnEvent(true);
                }
              } else if (swDIClientCheck === 'swBackwardCompatibleClient') {
                setSwUpdateClientView(true);
              }
            }

            /** used withSound parameter value, to show appropriate volume icon */
            if (clientSupportWithSound) {
              setTimerAudioSettingFromDI(data.timer?.withSound);
            }
          } else {
            //Backward Compatibility Check Code
            setBackwardCompatibleView(true);
          }
        })
        .catch((error) => {
          // Do nothing. This api call will always go into a catch block when the dynamic indicator is not set.
        });
    }
  }, [userContext, backwardCompatibleView, swUpdateClientView]);

  /**
   * This Effect adds the event listeners onSetDynamicIndicator and onRemoveDynamicIndicator.
   * The event listeners fire everytime a dynamic indicator is set or removed and instruct the app accordingly
   * to perform start, resume, pause or cancel actions for both Timer and Stopwatch.
   */
  useEffect(() => {
    const removeDynamicIndicatorHandler = (event) => {
      setIsTimerDISet(false);
      setIsStopwatchDISet(false);
      if (isTimerDISet) {
        setDynamicTimerRemoveEvent(true);
        setTimerVideoToggleOn(true);
        setDynamicTimerOnEvent(null);
      }
      if (isStopwatchDISet) {
        setDynamicStopwatchRemoveEvent(true);
        setStopwatchVideoToggleOn(true);
        setDynamicStopwatchOnEvent(null);
      }
      setMyDynamicIndicator(false);
      //Backward Compatibility Check Code
      setBackwardCompatibleView(false);
      setSwUpdateClientView(false);
    };
    if (
      userContext !== null &&
      supportRunningContext &&
      (dynamicIndicatorClientCheck === 'dISupportedClient' ||
        dynamicIndicatorClientCheck === 'dIBackwardCompatibleClient')
    ) {
      zoomSdk.addEventListener('onSetDynamicIndicator', async (event) => {
        setScreenName(event?.screenName);
        event.timer.countNegativeAfterAlarm === true
          ? setClientSupportNegativeAlarm(true)
          : setClientSupportNegativeAlarm(false);
        if (dynamicIndicatorClientCheck === 'dISupportedClient') {
          if (userContext.participantUUID === event.participantUUID) {
            setMyDynamicIndicator(true);
          } else {
            setMyDynamicIndicator(false);
            setTimerVideoToggleOn(false);
            setStopwatchVideoToggleOn(false);
          }

          /**
           * Down direction runs for Timer and Up direction runs for Stopwatch.
           */
          if (event.timer.direction === 'down') {
            setIsTimerDISet(true);
            setIsStopwatchDISet(false);
            setCurrentTab('home');
            setDynamicTimerRemoveEvent(null);
            setDynamicStopwatchRemoveEvent(null);
            if (event.timer.action === 'start') {
              const eventTime = (event.timer.start / 1000).toString();
              if (userContext.participantUUID === event.participantUUID) {
                await window.localStorage.setItem('LastSetTime', eventTime);
              } else {
                await window.localStorage.setItem(
                  'PublisherSetTime',
                  eventTime
                );
              }
              setTimerTime(event.timer.start / 1000);
              setDynamicTimerOnEvent(true);
            } else if (event.timer.action === 'pause') {
              setTimerTime(event.timer.current / 1000);
              setDynamicTimerOnEvent(false);
            } else if (event.timer.action === 'resume') {
              if (!dynamicTimerOnEvent) {
                setDynamicTimerOnEvent(true);
              }
            }
          } else if (event.timer.direction === 'up') {
            if (swDIClientCheck === 'swSupportedClient') {
              setIsStopwatchDISet(true);
              setIsTimerDISet(false);
              setCurrentTab('stopwatch');
              setDynamicTimerRemoveEvent(null);
              setDynamicStopwatchRemoveEvent(null);
              if (event.timer.action === 'start') {
                setDynamicStopwatchOnEvent(true);
              } else if (event.timer.action === 'pause') {
                setDynamicStopwatchOnEvent(false);
              } else if (event.timer.action === 'resume') {
                if (!dynamicStopwatchOnEvent) {
                  setDynamicStopwatchOnEvent(true);
                }
              }
            } else if (swDIClientCheck === 'swBackwardCompatibleClient') {
              setSwUpdateClientView(true);
            }
          }

          /** used withSound parameter value, to show appropriate volume icon */
          if (clientSupportWithSound) {
            setTimerAudioSettingFromDI(event.timer?.withSound);
          }
        } else {
          //Backward Compatibility Check Code
          setBackwardCompatibleView(true);
        }
      });

      zoomSdk.addEventListener(
        'onRemoveDynamicIndicator',
        removeDynamicIndicatorHandler
      );
    }
    return () => {
      zoomSdk.removeEventListener('onSetDynamicIndicator');
      zoomSdk.removeEventListener(
        'onRemoveDynamicIndicator',
        removeDynamicIndicatorHandler
      );
    };
  }, [
    userContext,
    backwardCompatibleView,
    swUpdateClientView,
    isTimerDISet,
    isStopwatchDISet,
  ]);

  return (
    <div>
      <Splash />
      <div
        className='App'
        id='App'
        style={{
          visibility: 'hidden',
        }}
      >
        {(backwardCompatibleView || swUpdateClientView) && (
          <ClientCompatibilityNotice />
        )}
        {isMobileAndroid && <AndroidNotice />}
        {(isTimerDISet || isStopwatchDISet) &&
          !myDynamicIndicator &&
          userContext.role !== 'host' && <div id='di_participant_overlay' />}
        {(isTimerDISet || isStopwatchDISet) &&
          !myDynamicIndicator &&
          userContext.role === 'host' && <div id='di_host_overlay' />}
        {!backwardCompatibleView && !swUpdateClientView && !isMobileAndroid && (
          <NavHeader currentTab={currentTab} setCurrentTab={setCurrentTab} />
        )}
        {!backwardCompatibleView && !swUpdateClientView && !isMobileAndroid && (
          <Home
            currentTab={currentTab}
            timerIsRunning={timerIsRunning}
            setTimerIsRunning={setTimerIsRunning}
            timerVideoToggleOn={timerVideoToggleOn}
            setTimerVideoToggleOn={setTimerVideoToggleOn}
            stopwatchIsOn={stopwatchIsOn}
            stopwatchVideoToggleOn={stopwatchVideoToggleOn}
            dynamicTimerOnEvent={dynamicTimerOnEvent}
            dynamicTimerRemoveEvent={dynamicTimerRemoveEvent}
            timerTime={timerTime}
            setTimerTime={setTimerTime}
            myDynamicIndicator={myDynamicIndicator}
            userContext={userContext}
            countdownStatus={countdownStatus}
            setCountdownStatus={setCountdownStatus}
            isTimerDISet={isTimerDISet}
            isStopwatchDISet={isStopwatchDISet}
            somethingWentWrong={somethingWentWrong}
            setSomethingWentWrong={setSomethingWentWrong}
            setMeetingTimerDisabled={setMeetingTimerDisabled}
            meetingTimerDisabled={meetingTimerDisabled}
            size={size}
            width={width}
            timerAudioSettingFromDI={timerAudioSettingFromDI}
            isVideoDimensionsChange={isVideoDimensionsChange}
            clientSupportNegativeAlarm={clientSupportNegativeAlarm}
            setClientSupportNegativeAlarm={setClientSupportNegativeAlarm}
            screenName={screenName}
            isVideoTurnedOn={isVideoTurnedOn}
          />
        )}
        {!backwardCompatibleView && !swUpdateClientView && !isMobileAndroid && (
          <Stopwatch
            currentTab={currentTab}
            stopwatchIsOn={stopwatchIsOn}
            setStopwatchIsOn={setStopwatchIsOn}
            stopwatchVideoToggleOn={stopwatchVideoToggleOn}
            setStopwatchVideoToggleOn={setStopwatchVideoToggleOn}
            timerIsRunning={timerIsRunning}
            timerVideoToggleOn={timerVideoToggleOn}
            myDynamicIndicator={myDynamicIndicator}
            isStopwatchDISet={isStopwatchDISet}
            isTimerDISet={isTimerDISet}
            userContext={userContext}
            size={size}
            dynamicStopwatchOnEvent={dynamicStopwatchOnEvent}
            dynamicStopwatchRemoveEvent={dynamicStopwatchRemoveEvent}
            stopwatchTime={stopwatchTime}
            setStopwatchTime={setStopwatchTime}
            stopwatchPaused={stopwatchPaused}
            setStopwatchPaused={setStopwatchPaused}
            somethingWentWrong={somethingWentWrong}
            setSomethingWentWrong={setSomethingWentWrong}
            setMeetingTimerDisabled={setMeetingTimerDisabled}
            meetingTimerDisabled={meetingTimerDisabled}
            width={width}
            isVideoDimensionsChange={isVideoDimensionsChange}
            screenName={screenName}
            isVideoTurnedOn={isVideoTurnedOn}
          />
        )}
      </div>
    </div>
  );
}
