/* globals zoomSdk */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

let zaConfigTimer;

export const zaConfig = createAsyncThunk(
  'app/zaConfig',
  async (query, thunkAPI) => {
    clearTimeout(zaConfigTimer);

    const config = await zoomSdk.config({
      capabilities: [
        'setVirtualForeground',
        'removeVirtualForeground',
        'getRunningContext',
        'onMyMediaChange',
        'setDynamicIndicator',
        'removeDynamicIndicator',
        'getDynamicIndicator',
        'onSetDynamicIndicator',
        'onRemoveDynamicIndicator',
        'getUserContext',
        'onMyUserContextChange',
        'onVideoSettingsChange',
        'getVideoSettings',
        'setVideoSettings',
        'setVideoState',
      ],
      version: '0.16',
    });

    zaConfigTimer = setTimeout(() => {
      thunkAPI.dispatch(zaConfig());
    }, 120 * 60 * 1000);

    return config;
  }
);

export const cancelZaConfig = createAction('app/cancelZaConfig', () => {
  clearTimeout(zaConfigTimer);
  return {};
});

export const updateStatus = createAction('app/status');
