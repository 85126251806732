/* globals zoomSdk */
import { useEffect, useState, useCallback } from 'react';
import './Stopwatch.css';
import { DisplaySWForOthersButton, SWControlButton } from '../home/Buttons';
import useSupportRunningContext from '../hooks/useSupportRunningContext';
import ControlSwitchModal from '../utilities/ControlSwitchModal';
import Canvas from '../utilities/Canvas';
import useEnterEscKeyPress from '../hooks/useEnterEscKeyPress';
import useOperationSystem from '../hooks/useOperatingSystem';
import CustomTooltip from '../utilities/CustomTooltip/CustomTooltip';
import useStopwatchDIClientCheck from '../hooks/useStopwatchDIClientCheck';
import NotificationToast from '../utilities/NotificationToast';
import { BLUE } from '../utilities/Constants';
import ToastComponent from '../utilities/Toast Component/ToastComponent';
import { WHITE } from '../utils';

let Interval;
// these variables are used for counting
let seconds = 0;
let minutes = 0;
let hours = 0;

function Stopwatch({
  currentTab,
  timerIsRunning,
  timerVideoToggleOn,
  stopwatchIsOn,
  setStopwatchIsOn,
  stopwatchVideoToggleOn,
  setStopwatchVideoToggleOn,
  myDynamicIndicator,
  isStopwatchDISet,
  isTimerDISet,
  userContext,
  size,
  dynamicStopwatchOnEvent,
  dynamicStopwatchRemoveEvent,
  stopwatchTime,
  setStopwatchTime,
  stopwatchPaused,
  setStopwatchPaused,
  somethingWentWrong,
  setSomethingWentWrong,
  setMeetingTimerDisabled,
  meetingTimerDisabled,
  width,
  isVideoDimensionsChange,
  screenName,
  isVideoTurnedOn,
}) {
  // vfDidDisplayOnVideo is the source of truth if VF is successfully display on the video
  const [vfDidDisplayOnVideo, setVfDidDisplayOnVideo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const supportRunningContext = useSupportRunningContext();
  // use state variables as strings for display purpose
  const [displayMinutes, setDisplayMinutes] = useState('00');
  const [displaySeconds, setDisplaySeconds] = useState('00');
  const [displayHours, setDisplayHours] = useState('00');
  const isMacOS = useOperationSystem();
  const swDIClientCheck = useStopwatchDIClientCheck();

  const activeStopwatchRunning = screenName
    ? screenName + "'s" + ' stopwatch is running. Controls are disabled.'
    : 'An active stopwatch is running. Controls are disabled.';

  // remove VF when not sharing SW with everyone and stopwatch is not started
  useEffect(() => {
    if (!stopwatchVideoToggleOn && !stopwatchIsOn && vfDidDisplayOnVideo) {
      zoomSdk.callZoomApi('removeVirtualForeground').catch((error) => {
        /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs do it function but still throws an error saying something went wrong.
        Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
      });
      setVfDidDisplayOnVideo(false);
    }
  }, [stopwatchVideoToggleOn, stopwatchIsOn]);

  /** let both stopwatch and timer run out of meeting */
  useEffect(() => {
    if (
      timerIsRunning &&
      timerVideoToggleOn &&
      stopwatchVideoToggleOn &&
      supportRunningContext
    ) {
      watchResetLogic();
    }
  }, [stopwatchVideoToggleOn, timerIsRunning, timerVideoToggleOn]);
  /**
   * This effect runs for Subscriber only, in the case when a personal stopwatch is running and a participant
   * publishes a Dynamic Indicator.
   */
  useEffect(() => {
    if ((isTimerDISet || isStopwatchDISet) && !myDynamicIndicator) {
      watchResetLogic();
    }
  }, [isTimerDISet, isStopwatchDISet, myDynamicIndicator]);

  /**
   * This Effect runs when dynamicStopwatchOnEvent is true.
   * i.e. when an onSetDynamicIndicator event is received by the App, or when the app is started or refreshed.
   */
  useEffect(() => {
    if (dynamicStopwatchOnEvent === true || dynamicStopwatchOnEvent === false) {
      stopwatchControllerLogic();
    }
  }, [dynamicStopwatchOnEvent]);
  /**
   * This Effect runs when dynamicTimerRemoveEvent is true. i.e. when an onRemoveDynamicIndicator event is received by the App.
   * It effectively resets the Stopwatch and removes the Virtual foreground.
   */
  useEffect(() => {
    if (dynamicStopwatchRemoveEvent === true) {
      watchResetLogic();
    }
  }, [dynamicStopwatchRemoveEvent]);

  useEffect(() => {
    setTime();
  }, [stopwatchTime]);

  function onCloseModal() {
    setOpenModal(false);
  }

  function onCancelModal() {
    setOpenModal(false);
  }

  /**
   *This function removes the dynamic Indicator started by the Timer, if present and stops it, before starting the stopwatch.
   * @returns {Promise<void>}
   */
  async function onContinueModal() {
    setOpenModal(false);
    if (isTimerDISet && myDynamicIndicator) {
      zoomSdk
        .callZoomApi('removeDynamicIndicator')
        .then(setTimeout(setStopwatchDynamicIndicator, 300));
    } else {
      setStopwatchDynamicIndicator();
    }
  }

  function swController() {
    if (
      supportRunningContext &&
      timerIsRunning &&
      timerVideoToggleOn &&
      stopwatchVideoToggleOn
    ) {
      setOpenModal(true);
    } else {
      setStopwatchDynamicIndicator();
    }
  }
  /**
   *This function sets the stopwatch dynamic indicator if supported else, starts the stopwatch.
   */
  function setStopwatchDynamicIndicator() {
    if (
      supportRunningContext &&
      stopwatchVideoToggleOn &&
      swDIClientCheck === 'swSupportedClient'
    ) {
      if (!stopwatchIsOn) {
        /* This state means that the Stopwatch is Off. It is either not started yet or it has ended. */
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'start',
              direction: 'up',
              start: 0,
            },
            borderColor: BLUE,
            textColor: WHITE,
          })
          .catch((error) => {
            if (
              error.toString() ===
              'Error: Meeting dropdown feature is disabled.'
            ) {
              setMeetingTimerDisabled(true);
            } else {
              setSomethingWentWrong(true);
            }
          });
      } else if (stopwatchIsOn && !stopwatchPaused) {
        /* This state means that the Stopwatch is On and currently running. */
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'pause',
            },
            borderColor: BLUE,
            textColor: WHITE,
          })
          .catch((error) => {
            setSomethingWentWrong(true);
          });
      } else if (stopwatchIsOn && stopwatchPaused) {
        /* This state means that the Stopwatch is On and currently paused. */
        zoomSdk
          .callZoomApi('setDynamicIndicator', {
            timer: {
              action: 'resume',
            },
            borderColor: BLUE,
            textColor: WHITE,
          })
          .catch((error) => {
            setSomethingWentWrong(true);
          });
      }
    } else {
      stopwatchControllerLogic();
    }
  }
  /**
   * This function extracts the hours, minutes and seconds from stopwatchTime.
   * stopwatchTime is set when getDynamicIndicator function is called
   * or when the timer app is started.
   */
  function setTime() {
    hours = Math.trunc(stopwatchTime / (1000 * 60 * 60));
    minutes = Math.trunc((stopwatchTime / (1000 * 60)) % 60);
    seconds = Math.trunc((stopwatchTime / 1000) % 60);

    hours >= 0 && hours <= 9
      ? setDisplayHours('0' + hours)
      : setDisplayHours(hours);

    minutes >= 0 && minutes <= 9
      ? setDisplayMinutes('0' + minutes)
      : setDisplayMinutes(minutes);

    seconds >= 0 && seconds <= 9
      ? setDisplaySeconds('0' + seconds)
      : setDisplaySeconds(seconds);
  }

  function stopwatchControllerLogic() {
    if (!stopwatchIsOn && !stopwatchPaused) {
      setStopwatchIsOn(true);
      setStopwatchPaused(false);
      clearInterval(Interval);
      Interval = setInterval(startTimer, 1000);
    }
    if (stopwatchIsOn && !stopwatchPaused) {
      setStopwatchIsOn(true);
      setStopwatchPaused(true);
      clearInterval(Interval);
    }
    if (stopwatchIsOn && stopwatchPaused) {
      setStopwatchPaused(false);
      Interval = setInterval(startTimer, 1000);
    }
  }

  // a custom hook listen to 'enter' and 'escape' key press
  useEnterEscKeyPress({
    isCurrentTab: currentTab === 'stopwatch',
    openModal,
    startHandler: swController,
    onContinueModal,
    isDynamicIndicatorSet: isStopwatchDISet,
    myDynamicIndicator,
    onCancelModal,
    resetWatch: watchReset,
  });

  function watchReset() {
    if (isStopwatchDISet) {
      zoomSdk.callZoomApi('removeDynamicIndicator').catch((error) => {});
    } else {
      watchResetLogic();
    }
  }

  function watchResetLogic() {
    clearInterval(Interval);
    setStopwatchTime(0);
    seconds = 0;
    minutes = 0;
    hours = 0;
    setDisplayHours('00');
    setDisplayMinutes('00');
    setDisplaySeconds('00');
    setStopwatchIsOn(false);
    setStopwatchPaused(false);
  }

  const formatTimerCanvas = useCallback(() => {
    return (
      <>
        {supportRunningContext && (
          <Canvas
            hours={displayHours}
            minutes={displayMinutes}
            seconds={displaySeconds}
            // always display blue border.
            // startTimer and timerTime are used for progress bar, for SW we don't require that behavior. So, just pass 1.
            startTime={1}
            timerTime={1}
            // only display SW on VF when sharing with everyone and stopwatch is started
            watchOnVideo={stopwatchVideoToggleOn && stopwatchIsOn}
            watchType='stopwatch'
            vfOnVideo={vfDidDisplayOnVideo}
            setVfOnVideo={setVfDidDisplayOnVideo}
            size={size}
            width={width}
            isVideoDimensionsChange={isVideoDimensionsChange}
            isVideoTurnedOn={isVideoTurnedOn}
          />
        )}
      </>
    );
  }, [
    supportRunningContext,
    displayHours,
    displayMinutes,
    displaySeconds,
    stopwatchVideoToggleOn,
    stopwatchIsOn,
    size,
    width,
    isVideoDimensionsChange,
    isVideoTurnedOn,
  ]);

  function startTimer() {
    seconds++;
    if (seconds >= 60) {
      minutes++;
      seconds = 0;
      setDisplaySeconds('00');
    }
    if (minutes >= 60) {
      hours++;
      minutes = 0;
      setDisplayMinutes('00');
    }
    if (seconds > 9) {
      setDisplaySeconds(seconds);
    } else {
      setDisplaySeconds('0' + seconds);
    }
    if (minutes > 9) {
      setDisplayMinutes(minutes);
    } else {
      setDisplayMinutes('0' + minutes);
    }
    if (hours > 9) {
      setDisplayHours(hours);
    } else {
      setDisplayHours('0' + hours);
    }

    // if stopwatch exceeds two digits, reset stopwatch. Resetting SW will stop it so, no VF will be shown.
    if (hours >= 99 && minutes >= 59 && seconds >= 59) {
      watchReset();
    }
  }
  /*  {somethingWentWrong && (
    <ToastComponent
      usage='error_toast'
      body='Something went wrong! Please try again.'
    />
  )}
  ;*/
  return (
    <>
      <div
        className='Sw_container'
        style={{
          visibility: currentTab === 'stopwatch' ? 'inherit' : 'hidden',
        }}
      >
        {meetingTimerDisabled && (
          <ToastComponent
            usage='error_toast'
            body='Meeting Timers have been disabled by the Host.'
          />
        )}
        ;
        {isStopwatchDISet && !myDynamicIndicator && (
          <NotificationToast
            title={activeStopwatchRunning}
            dismiss={false}
            type='error'
          />
        )}
        {openModal && (
          <ControlSwitchModal
            title={'Begin Stopwatch for Everyone'}
            message={
              "You're about to show your stopwatch to everyone. It will replace the active timer."
            }
            onCancelFunction={onCancelModal}
            onCloseFunction={onCloseModal}
            onContinueFunction={onContinueModal}
          />
        )}
        <div className='Sw_Main_container'>
          <div data-testid='timerContainer' className='Sw'>
            <span id='hours' className='Stopwatch__timer'>
              {displayHours}
            </span>
            <span id='hoursSpacer' className='Stopwatch__timer'>
              :
            </span>
            <span id='hoursLabel' className='time_units'>
              hr
            </span>
            <span id='minutes' className='Stopwatch__timer'>
              {displayMinutes}
            </span>
            <span id='minutesSpacer' className='Stopwatch__timer'>
              :
            </span>
            <span id='minutesLabel' className='time_units'>
              min
            </span>
            <span id='seconds' className='Stopwatch__timer'>
              {displaySeconds}
            </span>
            <span id='secondsLabel' className='time_units'>
              sec
            </span>
          </div>
          <div className='Sw__controlTime'>
            <div className='Sw__cancel_button'>
              <button
                id='Sw_reset_button'
                data-tooltip-id='sw-cancelbutton-tooltip'
                data-tooltip-delay-show={300}
                className='Sw__time_button'
                onClick={watchReset}
                disabled={
                  isStopwatchDISet &&
                  !myDynamicIndicator &&
                  userContext.role !== 'host'
                }
              >
                Reset
              </button>
              <CustomTooltip
                text='Keyboard shortcut'
                keyName='esc'
                id='sw-cancelbutton-tooltip'
              />
            </div>
            <SWControlButton
              stopwatchIsOn={stopwatchIsOn}
              stopwatchPaused={stopwatchPaused}
              swController={swController}
              isMacOS={isMacOS}
              disabled={isStopwatchDISet && !myDynamicIndicator}
            />
          </div>
        </div>
        {stopwatchVideoToggleOn && formatTimerCanvas()}
        {supportRunningContext && (
          <DisplaySWForOthersButton
            displayOnVideo={stopwatchVideoToggleOn}
            onClickFunction={() =>
              setStopwatchVideoToggleOn(!stopwatchVideoToggleOn)
            }
            text='Show stopwatch to all'
            disabled={stopwatchIsOn}
          />
        )}
      </div>
    </>
  );
}

export default Stopwatch;
