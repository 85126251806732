export default function Splash() {
  return (
    <div id='splash'>
      <div className='splashTitle'>
        <img
          src={`${process.env.PUBLIC_URL}/Timer-logo.png`}
          alt='timer logo'
        />
      </div>
      <div className='zoomLogo'>
        <img
          src={`${process.env.PUBLIC_URL}/Zoom-logo.png`}
          alt='timer logo'
        ></img>
      </div>
    </div>
  );
}
