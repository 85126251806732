import { useEffect } from 'react';

const useMediaSession = () => {
  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: 'Zoom App Timer',
        artwork: [
          {
            src: `${process.env.PUBLIC_URL}/logo192.png`,
            sizes: '128x128',
            type: 'image/jpg',
          },
        ],
      });
      // overriding play behavior to play nothing (originally it plays alarm sound if it is clicked)
      navigator.mediaSession.setActionHandler('play', () => {});
    }
  }, []);
};

export default useMediaSession;
