/** AlarmOnIcon and AlarmOffIcon are icon used in toggle timer alarm on and off respectively */
export const AlarmOnIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_3497_386'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path
          d='M9.5 15.25V0.749949C9.5 0.134839 8.8003 -0.214887 8.3051 0.149996L4.75 3.49998H3C1.34315 3.49998 0 4.84312 0 6.49998V9.49998C0 11.1568 1.34315 12.5 3 12.5H4.75L8.3051 15.85C8.8003 16.2149 9.5 15.8651 9.5 15.25Z'
          fill='#000001'
        />
        <path
          d='M11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646C13.4295 6.72242 13.4295 9.27756 11.8536 10.8535C11.6583 11.0488 11.3417 11.0488 11.1464 10.8535C10.9512 10.6583 10.9512 10.3417 11.1464 10.1464C12.3319 8.96098 12.3319 7.03901 11.1464 5.85357C10.9512 5.65831 10.9512 5.34172 11.1464 5.14646Z'
          fill='#000001'
        />
        <path
          d='M13.8536 3.14646C13.6583 2.9512 13.3417 2.9512 13.1464 3.14646C12.9512 3.34172 12.9512 3.65831 13.1464 3.85357C15.4365 6.14359 15.4365 9.85644 13.1464 12.1465C12.9512 12.3417 12.9512 12.6583 13.1464 12.8536C13.3417 13.0488 13.6583 13.0488 13.8536 12.8536C16.5341 10.173 16.5341 5.827 13.8536 3.14646Z'
          fill='#000001'
        />
      </mask>
      <g mask='url(#mask0_3497_386)'>
        <rect width='16' height='16' fill='#F7F9FA' />
      </g>
    </svg>
  );
};

export const AlarmOffIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_2599_87031'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path
          d='M9.5 15.25V0.749949C9.5 0.134839 8.8003 -0.214887 8.3051 0.149996L4.75 3.49998H3C1.34315 3.49998 0 4.84312 0 6.49998V9.49998C0 11.1568 1.34315 12.5 3 12.5H4.75L8.3051 15.85C8.8003 16.2149 9.5 15.8651 9.5 15.25Z'
          fill='#000001'
        />
        <path
          d='M11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646C13.4295 6.72242 13.4295 9.27756 11.8536 10.8535C11.6583 11.0488 11.3417 11.0488 11.1464 10.8535C10.9512 10.6583 10.9512 10.3417 11.1464 10.1464C12.3319 8.96098 12.3319 7.03901 11.1464 5.85357C10.9512 5.65831 10.9512 5.34172 11.1464 5.14646Z'
          fill='#000001'
        />
        <path
          d='M13.8536 3.14646C13.6583 2.9512 13.3417 2.9512 13.1464 3.14646C12.9512 3.34172 12.9512 3.65831 13.1464 3.85357C15.4365 6.14359 15.4365 9.85644 13.1464 12.1465C12.9512 12.3417 12.9512 12.6583 13.1464 12.8536C13.3417 13.0488 13.6583 13.0488 13.8536 12.8536C16.5341 10.173 16.5341 5.827 13.8536 3.14646Z'
          fill='#000001'
        />
      </mask>
      <g mask='url(#mask0_2599_87031)'>
        <rect width='16' height='16' fill='#F7F9FA' />
      </g>
      <mask
        id='mask1_2599_87031'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.8536 0.146447C16.0488 0.341709 16.0488 0.658291 15.8536 0.853553L0.853553 15.8536C0.658291 16.0488 0.341709 16.0488 0.146447 15.8536C-0.0488155 15.6583 -0.0488155 15.3417 0.146447 15.1464L15.1464 0.146447C15.3417 -0.0488155 15.6583 -0.0488155 15.8536 0.146447Z'
          fill='#ED4D6A'
        />
      </mask>
      <g mask='url(#mask1_2599_87031)'>
        <rect width='16' height='16' fill='#F21F31' />
      </g>
    </svg>
  );
};
