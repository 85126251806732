import { useSelector } from 'react-redux';
import { selectZaClientVersion, selectZaConfig } from '../root/reducer';
import { useEffect, useState } from 'react';

function useDynamicIndicatorClientCheck() {
  const clientVersion = useSelector(selectZaClientVersion);
  const [clientSupportDI, setClientSupportDI] = useState('');
  const config = useSelector(selectZaConfig);
  const unsupportedApis = config !== null ? config.unsupportedApis : null;
  const dynamicIndicatorApiSupported =
    unsupportedApis === null
      ? false
      : !unsupportedApis.includes(
          'onSetDynamicIndicator',
          'onRemoveDynamicIndicator',
          'removeDynamicIndicator',
          'setDynamicIndicator',
          'getDynamicIndicator',
          'getUserContext'
        );
  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    if (
      ((cv1 === 5 && cv2 === 14 && cv3 >= 10) ||
        (cv1 >= 5 && cv2 > 14) ||
        cv1 >= 6) &&
      dynamicIndicatorApiSupported
    ) {
      setClientSupportDI('dISupportedClient');
    } else if (
      cv1 === 5 &&
      ((cv2 === 13 && cv3 >= 10) || (cv2 === 14 && cv3 < 10))
    ) {
      setClientSupportDI('dIBackwardCompatibleClient');
    } else {
      setClientSupportDI('dIUnsupportedClient');
    }
  }, [clientVersion]);
  return clientSupportDI;
}
export default useDynamicIndicatorClientCheck;
