import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { musicList } from './MusicList';
import Chevron from './Icons/Chevron';
import './MusicOptionsList.css';
import PlayIcon from './Icons/PlayIcon';
import {
  setTimerAudioState,
  setMusicSelectedOption,
  setCurrentPreviewMusic,
  setPreviousSelectedMusicOption,
} from './actions';
import { selectedTimerMusicOption, selectCurrentPreviewMusic } from './reducer';
import { AlarmOffIcon } from './Icons/VolumeIcons';
import CheckMarkIcon from './Icons/CheckMarkIcon';
import PlayButtonIndicator from './Icons/PlayButtonIndicator';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

/** This component shows and manages music options list. It sets and preview music options. */
function MusicOptionsList({ refs }) {
  const dispatch = useDispatch();
  const selectedOption = useSelector(selectedTimerMusicOption);
  const currentPreviewMusic = useSelector(selectCurrentPreviewMusic);
  const [showList, setShowList] = useState(false);
  const popOverRef = useRef(null);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);

  useEffect(() => {
    function handleClickOutsidePopOver(event) {
      if (popOverRef.current && !popOverRef.current.contains(event.target)) {
        setShowList(false);
      }
    }
    document.addEventListener('click', handleClickOutsidePopOver);
    return () => {
      document.removeEventListener('click', handleClickOutsidePopOver);
    };
  }, []);

  function optionsButtonClick() {
    setShowList(!showList);
  }

  const setMusicOption = useCallback(
    (option, id) => {
      // turn on audio if some other option is selected
      if (option !== 'Off') {
        dispatch(setTimerAudioState(true));
      } else {
        // if audio is off, store current selected option as previous value
        dispatch(
          setPreviousSelectedMusicOption({
            name: selectedOption.name,
            id: selectedOption.id,
          })
        );
      }
      window.localStorage.setItem(
        'preferredMusic',
        JSON.stringify({ name: option, id: id })
      );
      dispatch(setMusicSelectedOption({ name: option, id: id }));
      setShowList(false);
    },
    [dispatch, selectedOption]
  );

  /** This function is used to preview music. Before playing music pause the existing one, if not both music files will play at a time*/
  const playMusic = (id) => {
    pauseMusic(currentPreviewMusic);
    let currentSelectedMusicRef = refs[id].ref;
    if (currentSelectedMusicRef.current.src) {
      dispatch(setCurrentPreviewMusic(id));
      currentSelectedMusicRef.current.muted = false;
      currentSelectedMusicRef.current
        .play()
        .then(() => {
          setIsPreviewPlaying(true);
        })
        .catch((error) => {
          console.log(error);
        });
      currentSelectedMusicRef.current.addEventListener('ended', () => {
        setIsPreviewPlaying(false);
      });
    }
  };

  const pauseMusic = (id) => {
    let currentPreviewMusicRef = refs[id].ref;
    if (currentPreviewMusicRef.current.src) {
      currentPreviewMusicRef.current.pause();
      currentPreviewMusicRef.current.currentTime = 0;
    }
  };

  return (
    <div ref={popOverRef}>
      <button
        className={`musicSelectedOption ${showList ? 'showList' : ''}`}
        onClick={optionsButtonClick}
      >
        <p
          className={`${
            selectedOption.name === 'Off' ? 'smallerText' : 'selectedOptionText'
          }`}
        >
          {selectedOption.name}
        </p>
        <span>
          <Chevron />
        </span>
      </button>
      {showList && (
        <div className='optionsPopOver'>
          <div className='listHeading'>Alarm when the timer ends:</div>
          <div className='musicOptions'>
            <div key='music_off' className='musicOption'>
              <div
                className={`${
                  selectedOption.name === 'Off'
                    ? 'selectedOption'
                    : 'notselectedOption'
                }`}
              >
                <CheckMarkIcon />
              </div>
              <AlarmOffIcon />
              <span
                onClick={() => {
                  dispatch(setTimerAudioState(false));
                  setMusicOption('Off', -1);
                }}
              >
                Off
              </span>
              <button
                onClick={() => {
                  dispatch(setTimerAudioState(false));
                  setMusicOption('Off', -1);
                }}
                className='offSetButton'
              >
                Set
              </button>
            </div>
            {musicList.map((option) => {
              return (
                <div className='musicOption' key={option.id}>
                  <div
                    className={`${
                      selectedOption.id === option.id
                        ? 'selectedOption'
                        : 'notselectedOption'
                    }`}
                  >
                    <CheckMarkIcon />
                  </div>
                  <span onClick={() => setMusicOption(option.name, option.id)}>
                    {option.name}
                  </span>
                  <button
                    onClick={() => setMusicOption(option.name, option.id)}
                    className='setMusicButton'
                  >
                    Set
                  </button>
                  {isPreviewPlaying && currentPreviewMusic === option.id ? (
                    <PlayButtonIndicator />
                  ) : (
                    <div className='musicPreviewButton'>
                      <button
                        data-tooltip-id={option.id}
                        data-tooltip-delay-show={300}
                        data-tooltip-place='top'
                        onClick={() => playMusic(option.id)}
                        className='musicPlayIcon'
                        key={option.id}
                      >
                        <PlayIcon />
                      </button>
                      <CustomTooltip text='Play for me' id={option.id} />
                    </div>
                  )}
                </div>
              );
            })}
            <div key='default_music_option' className='musicOption'>
              <div
                className={`${
                  selectedOption.name === 'Beep'
                    ? 'selectedOption'
                    : 'notselectedOption'
                }`}
              >
                <CheckMarkIcon />
              </div>
              <span onClick={() => setMusicOption('Beep', 0)}>Beep</span>
              <button
                onClick={() => setMusicOption('Beep', 0)}
                className='setMusicButton'
              >
                Set
              </button>
              {isPreviewPlaying && currentPreviewMusic === 0 ? (
                <PlayButtonIndicator />
              ) : (
                <div className='musicPreviewButton'>
                  <button
                    data-tooltip-id={0}
                    data-tooltip-delay-show={300}
                    data-tooltip-place='top'
                    aria-label='preview-button'
                    onClick={() => playMusic(0)}
                    className='musicPlayIcon'
                    key={0}
                  >
                    <PlayIcon />
                  </button>
                  <CustomTooltip text='Play for me' id={0} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MusicOptionsList;
