import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectZaUserAgent } from '../root/reducer';

function useOperationSystem() {
  const userAgent = useSelector(selectZaUserAgent);
  const [isMacOS, setIsMacOS] = useState(false);

  useEffect(() => {
    if (userAgent?.includes('Mac')) {
      setIsMacOS(true);
    }
  }, [userAgent]);
  return isMacOS;
}

export default useOperationSystem;
