/** CheckMarkIcon is shown beside selected music option in music options list */
function CheckMarkIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3491 3.32199C13.4119 3.36969 13.4648 3.42931 13.5045 3.49745C13.5443 3.56558 13.5702 3.64089 13.5808 3.71906C13.5915 3.79723 13.5866 3.87673 13.5664 3.95301C13.5463 4.02929 13.5113 4.10084 13.4635 4.16359L7.06351 12.5636C7.0116 12.6316 6.94573 12.6878 6.87033 12.7283C6.79492 12.7687 6.71173 12.7926 6.62634 12.7983C6.54094 12.804 6.45532 12.7913 6.37522 12.7612C6.29511 12.7311 6.22238 12.6841 6.16191 12.6236L2.56191 9.02359C2.45593 8.90985 2.39823 8.75941 2.40097 8.60397C2.40371 8.44853 2.46668 8.30022 2.57661 8.19029C2.68655 8.08036 2.83485 8.01739 2.99029 8.01465C3.14574 8.0119 3.29617 8.0696 3.40991 8.17559L6.52511 11.29L12.5091 3.43639C12.6054 3.30995 12.748 3.22689 12.9054 3.20544C13.0629 3.18399 13.2225 3.22591 13.3491 3.32199Z'
        fill='#4793F1'
      />
    </svg>
  );
}

export default CheckMarkIcon;
