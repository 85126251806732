import { useState, useCallback, useEffect } from 'react';
import { UpButton, DownButton } from './Buttons';

export default function SetTimer({
  hours,
  minutes,
  seconds,
  timerTime,
  setTimerTimeWithConstraint,
  setTimerTime,
  startTimerOnEnterPress,
  stopwatchIsOn,
  openModal,
  onContinueModal,
  supportRunningContext,
  timerVideoToggleOn,
  dynamicIndicatorClientCheck,
}) {
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const handleInputChange = useCallback(
    (e) => {
      if (!isNaN(e.target.valueAsNumber) && e.target.valueAsNumber <= 99) {
        if (e.target.name === 'hour') {
          setTimerTimeWithConstraint(
            e.target.valueAsNumber * 3600 +
              // for the first load, if user change hr/sec, min will be set to 0
              (firstLoad ? 0 : parseInt(minutes) || 0) * 60 +
              (parseInt(seconds) || 0)
          );
        } else if (e.target.name === 'min') {
          setTimerTimeWithConstraint(
            (parseInt(hours) || 0) * 3600 +
              e.target.valueAsNumber * 60 +
              (parseInt(seconds) || 0)
          );
        } else if (e.target.name === 'sec') {
          setTimerTimeWithConstraint(
            (parseInt(hours) || 0) * 3600 +
              // for the first load, if user change hr/sec, min will be set to 0
              (firstLoad ? 0 : parseInt(minutes) || 0) * 60 +
              e.target.valueAsNumber
          );
        }
        setFirstLoad(false);
      }
    },
    [hours, minutes, seconds, setTimerTimeWithConstraint]
  );

  const getValidTime = useCallback((time) => {
    if (time <= 0) {
      return 0;
    } else if (time >= 0 && time < 360000) {
      return time;
    } else {
      return 359999; // Max input: 99 hours, 59 minutes, 59 seconds
    }
  }, []);

  const handleWhiteSpace = useCallback(
    (e) => {
      switch (e.key) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '0':
        case 'Backspace':
        case 'Delete':
        case 'Tab':
        case 'ArrowLeft':
        case 'ArrowRight':
          break;
        case 'Control':
          setCtrlKeyPressed(true);
          break;
        case 'd':
          if (ctrlKeyPressed === false) {
            e.preventDefault();
          }
          break;
        case 'ArrowUp':
          if (e.target.name === 'hour') {
            setTimerTime(getValidTime(timerTime + 3600));
          } else if (e.target.name === 'min') {
            setTimerTime(getValidTime(timerTime + 60));
          } else if (e.target.name === 'sec') {
            setTimerTime(getValidTime(timerTime + 1));
          }
          break;
        case 'ArrowDown':
          if (e.target.name === 'hour') {
            setTimerTime(getValidTime(timerTime - 3600));
          } else if (e.target.name === 'min') {
            setTimerTime(getValidTime(timerTime - 60));
          } else if (e.target.name === 'sec') {
            setTimerTime(getValidTime(timerTime - 1));
          }
          break;
        default:
          e.preventDefault();
      }
    },
    [
      ctrlKeyPressed,
      timerTime,
      stopwatchIsOn,
      openModal,
      supportRunningContext,
      dynamicIndicatorClientCheck,
      timerVideoToggleOn,
    ]
  );

  const resetCtrlKeyPress = useCallback(() => {
    setCtrlKeyPressed(false);
  }, []);

  const handleIncrementDecrement = useCallback(
    (value) => {
      if (!intervalId) {
        setIntervalId(
          setInterval(() => {
            setTimerTime((previousTime) => getValidTime(previousTime + value));
          }, 250)
        );
      }
    },
    [intervalId]
  );

  const handleMouseUp = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  const handleRightClick = useCallback(
    (event) => {
      event.preventDefault();
      handleMouseUp();
    },
    [intervalId]
  );

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [intervalId]);

  return (
    <>
      <div className='hr-input-buttons-container'>
        <UpButton
          className='hr-up-button'
          label='Increase Hours'
          tabindex='2'
          onClickFunction={() => setTimerTimeWithConstraint(timerTime + 3600)}
          onMouseDown={() => handleIncrementDecrement(3600)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='hrUpButton'
        />

        <form>
          <input
            className={
              timerTime > 0
                ? 'Home__timer_input input-non-zero'
                : 'Home__timer_input input-zero'
            }
            id='input-hours'
            aria-labelledby='hourLabel'
            name='hour'
            type='number'
            inputMode='numeric'
            data-validate='number'
            min='0'
            max='99'
            placeholder='00'
            tabIndex='1'
            value={hours}
            onChange={handleInputChange}
            onKeyDown={handleWhiteSpace}
            onKeyUp={resetCtrlKeyPress}
          />
        </form>
        <span id='hourLabel' className='timer_time_units'>
          hr
        </span>
        <DownButton
          className='hr-down-button'
          label='Decrease Hours'
          tabindex='3'
          onClickFunction={() =>
            setTimerTimeWithConstraint(timerTime > 3600 ? timerTime - 3600 : 0)
          }
          onMouseDown={() => handleIncrementDecrement(-3600)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='hrDownButton'
        />
      </div>
      <span
        id='hourSpacer'
        className={
          timerTime > 0
            ? 'Home__timer_input input-non-zero'
            : 'Home__timer_input input-zero'
        }
      >
        :
      </span>
      <div className='min-input-buttons-container'>
        <UpButton
          className='min-up-button'
          label='Increase Minutes'
          tabindex='5'
          onClickFunction={() => setTimerTimeWithConstraint(timerTime + 60)}
          onMouseDown={() => handleIncrementDecrement(60)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='minUpButton'
        />
        <form>
          <input
            className={
              timerTime > 0
                ? 'Home__timer_input input-non-zero'
                : 'Home__timer_input input-zero'
            }
            id='input-minutes'
            aria-labelledby='minuteLabel'
            name='min'
            type='number'
            inputMode='numeric'
            data-validate='number'
            min='0'
            max='59'
            placeholder='00'
            tabIndex='4'
            value={minutes}
            onChange={handleInputChange}
            onKeyDown={handleWhiteSpace}
            onKeyUp={resetCtrlKeyPress}
          />
        </form>
        <span id='minuteLabel' className='timer_time_units'>
          min
        </span>
        <DownButton
          className='min-down-button'
          label='Decrease Minutes'
          tabindex='6'
          onClickFunction={() =>
            setTimerTimeWithConstraint(timerTime > 60 ? timerTime - 60 : 0)
          }
          onMouseDown={() => handleIncrementDecrement(-60)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='minDownButton'
        />
      </div>
      <span
        id='minuteSpacer'
        className={
          timerTime > 0
            ? 'Home__timer_input input-non-zero'
            : 'Home__timer_input input-zero'
        }
      >
        :
      </span>
      <div className='sec-input-buttons-container'>
        <UpButton
          className='sec-up-button'
          label='Increase Seconds'
          tabindex='8'
          onClickFunction={() => setTimerTimeWithConstraint(timerTime + 1)}
          onMouseDown={() => handleIncrementDecrement(1)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='secUpButton'
        />
        <form>
          <input
            className={
              timerTime > 0
                ? 'Home__timer_input input-non-zero'
                : 'Home__timer_input input-zero'
            }
            id='input-seconds'
            aria-labelledby='secondLabel'
            name='sec'
            type='number'
            inputMode='numeric'
            data-validate='number'
            min='0'
            max='59'
            placeholder='00'
            tabIndex='7'
            value={seconds}
            onChange={handleInputChange}
            onKeyDown={handleWhiteSpace}
            onKeyUp={resetCtrlKeyPress}
          />
        </form>
        <span id='secondLabel' className='timer_time_units'>
          sec
        </span>
        <DownButton
          className='sec-down-button'
          label='Decrease Seconds'
          tabindex='9'
          onClickFunction={() =>
            setTimerTimeWithConstraint(timerTime > 0 ? timerTime - 1 : 0)
          }
          onMouseDown={() => handleIncrementDecrement(-1)}
          onMouseUp={handleMouseUp}
          onContextMenu={handleRightClick}
          id='secDownButton'
        />
      </div>
    </>
  );
}
