import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import LocalTime from './LocalTime';
import {
  GREEN,
  WHITE,
  BLACK,
  determineTimerColor,
  determineBackgroundColor,
  determineTextColor,
} from '../utils';
import useDynamicIndicatorClientCheck from '../hooks/useDynamicIndicatorClientCheck';
import useWithSound from '../hooks/useWithSound';
import { selectedTimerMusicOption } from '../utilities/MusicInTimer/reducer';

export default function Countdown({
  countdownKey,
  hours,
  minutes,
  seconds,
  startTime,
  timerTime,
  timerStatus,
  myDynamicIndicator,
  setSomethingWentWrong,
  myTimerAudioToggleOn,
  timerAudioSettingFromDI,
  isTimerDISet,
}) {
  const dynamicIndicatorClientCheck = useDynamicIndicatorClientCheck();
  const [timerColor, setTimerColor] = useState(GREEN);
  const [timerTextColor, setTimerTextColor] = useState(WHITE);
  const [timerBackgroundColor, setTimerBackgroundColor] = useState(BLACK);
  const clientSupportWithSound = useWithSound();
  const selectedMusicOption = useSelector(selectedTimerMusicOption);

  function calculateOwnerStartTime() {
    const globalStartTime = myDynamicIndicator
      ? parseInt(window.localStorage.getItem('LastSetTime'))
      : parseInt(window.localStorage.getItem('PublisherSetTime'));
    return globalStartTime >= 0 ? globalStartTime : startTime;
  }
  useEffect(() => {
    const ownerStartTime = calculateOwnerStartTime();
    const timerColor = determineTimerColor(timerTime, ownerStartTime);
    const textColor = determineTextColor(timerTime);
    const backgroundColor = determineBackgroundColor(timerTime);
    setTimerColor(timerColor);
    setTimerTextColor(textColor);
    setTimerBackgroundColor(backgroundColor);
  }, [startTime, timerTime]);

  function calculateStartPixel() {
    const globalStartTime = myDynamicIndicator
      ? parseInt(window.localStorage.getItem('LastSetTime'))
      : parseInt(window.localStorage.getItem('PublisherSetTime'));
    return globalStartTime >= 0
      ? ((globalStartTime - startTime) / globalStartTime) * 736
      : 0;
  }
  useEffect(() => {
    const startPixel = calculateStartPixel();
    const css = window.document.styleSheets[0];
    css.insertRule(
      `@keyframes countdownAnimation {from  { stroke-dashoffset: ` +
        startPixel +
        `px; } to  { stroke-dashoffset: 736px; }
    }`,
      css.cssRules.length
    );
  }, [startTime]);

  /**
   * This function creates an additional after element on Home__timer_circle.
   * This after element creates the full red border around the circle.
   * @param container
   */
  function timerCircleCSS(container) {
    const afterElement = document.createElement('div');
    afterElement.className = 'red-border-element'; // Add a class for styling
    container.appendChild(afterElement);
    // Apply styles to the created red-border-element
    afterElement.style.borderRadius = '50%';
    afterElement.style.border = '4px solid #ED4D6A';
    afterElement.style.backgroundColor = '#43131C';
    afterElement.style.height = '240px';
    afterElement.style.display = 'block';
    afterElement.style.content = '';
    afterElement.style.left = 'calc((100% - 240px) / 2)';
    afterElement.style.position = 'absolute';
    afterElement.style.top = '4%';
    afterElement.style.zIndex = '1';
    afterElement.style.width = '240px';
  }

  /**
   * This usEffect creates an additional after element on Home__timer_circle.
   * This after element creates the red border around the circle when time is 0.
   */
  useEffect(() => {
    const container = document.querySelector('.Home__timer_circle');
    if (container && timerTime === 0) {
      timerCircleCSS(container);
    }
  }, [timerTime]);
  /**
   * This usEffect creates an additional after element on Home__timer_circle.
   * This after element creates the red border around the circle when time is <0.
   * This typically happens when the app is refreshed or opened when the DI is already in negative.
   */
  useEffect(() => {
    const container = document.querySelector('.Home__timer_circle');
    if (container && timerTime < 0) {
      timerCircleCSS(container);
    }
  }, []);

  /**
   * This use effect is used to change the border color of the Timer ring
   * as per the Time change.
   */
  useEffect(() => {
    if (
      isTimerDISet &&
      myDynamicIndicator &&
      dynamicIndicatorClientCheck === 'dISupportedClient'
    ) {
      zoomSdk
        .callZoomApi('setDynamicIndicator', {
          timer: {
            action: 'resume',
            withSound: myTimerAudioToggleOn,
            showNotification: true,
            songChoice: parseInt(selectedMusicOption.id),
            countNegativeAfterAlarm: true,
          },
          borderColor: timerColor,
          backgroundColor: timerBackgroundColor,
          textColor: timerTextColor,
        })
        .catch((error) => {
          setSomethingWentWrong(true);
        });
    }
  }, [
    timerColor,
    timerTextColor,
    timerBackgroundColor,
    myDynamicIndicator,
    clientSupportWithSound,
    myTimerAudioToggleOn,
    selectedMusicOption,
  ]);

  // if dynamic indicator is set and has audio setting, show audio state from DI. Else, show personal audio setting
  // in client version 5.15.10 withSound parameter datatype would be boolean, before it is a string. So addressing for both types.
  const determineTimerAudioSetting = useCallback(() => {
    if (isTimerDISet && timerAudioSettingFromDI !== 'notsupported') {
      if (
        timerAudioSettingFromDI === 'true' ||
        timerAudioSettingFromDI === true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return myTimerAudioToggleOn;
    }
  }, [timerAudioSettingFromDI, myTimerAudioToggleOn, isTimerDISet]);

  return (
    <>
      <div className='Home__timer_circle'></div>
      <div className='Home__timer_countdown'>
        <div
          id='countdown'
          key={countdownKey}
          className='Home__timer_countdown_circle'
          style={{
            animation: timerStatus
              ? 'countdownAnimation ' + startTime + 's linear running'
              : 'countdownAnimation ' + startTime + 's linear paused',
            stroke: timerColor,
          }}
        >
          <svg>
            <circle r='117.5' cx='94' cy='94' />
          </svg>
        </div>
      </div>
      <div className='countdownDisplayTimes'>
        {Math.abs(hours) > 0 && (
          <>
            <span className='Home__timer_hour'>{hours}</span>
            <span className='time_colon'>:</span>
          </>
        )}
        <span className='time_min'>
          {timerTime < 0 && Math.abs(hours) > 0
            ? minutes.substring(1)
            : minutes}
        </span>
        <span className='time_colon'>:</span>
        <span className={`time_sec countdown`}>
          {timerTime < 0 ? seconds.substring(1) : seconds}
        </span>
      </div>
      <LocalTime
        timerTime={timerTime}
        timerAudioToggleOn={determineTimerAudioSetting()}
        timerStatus={timerStatus}
      />
    </>
  );
}
