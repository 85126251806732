import { useSelector } from 'react-redux';
import { selectZaClientVersion } from '../root/reducer';
import { useEffect, useState } from 'react';
import useIsDesktopCheck from './useIsDesktopCheck';

/**
 * This hook returns true/false if client supports Negative Alarm feature or not for Dynamic Indicator APIs.
 * @returns {string}
 */
function useCountNegativeAfterAlarm() {
  const clientVersion = useSelector(selectZaClientVersion);
  const isDesktop = useIsDesktopCheck();
  const [countNegativeAfterAlarm, setCountNegativeAfterAlarm] = useState('');

  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    /*
     countNegativeAfterAlarm parameter in DI is supported in Desktop client >= 5.16.5
     */
    if (isDesktop) {
      if (
        (cv1 === 5 && cv2 === 16 && cv3 >= 5) ||
        (cv1 === 5 && cv2 >= 17) ||
        cv1 >= 6
      ) {
        setCountNegativeAfterAlarm(true);
      } else {
        setCountNegativeAfterAlarm(false);
      }
    } else {
      //Condition for Mobile check. This parameter is supported in Mobile.
      setCountNegativeAfterAlarm(true);
    }
  }, [clientVersion, isDesktop]);
  return countNegativeAfterAlarm;
}

export default useCountNegativeAfterAlarm;
