import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectZaConfig } from '../root/reducer';

function useMobileAndroidCheck() {
  const config = useSelector(selectZaConfig);
  const [isMobileAndroid, setIsMobileAndroid] = useState(false);

  // This hook is used to identify if the Timer app is running on Mobile Android device.
  // If Timer app is running on Mobile device (iPhone, Android phone) and iPad, the config.product will be `mobile`.
  // And userAgent string on Android mobile will contain `Android` word
  useEffect(() => {
    if (
      config &&
      config?.product === 'mobile' &&
      config?.userAgent?.includes('Android')
    ) {
      setIsMobileAndroid(true);
    }
  }, [config]);
  return isMobileAndroid;
}

export default useMobileAndroidCheck;
