/**
 *This is a workaround function to play sound immediately after a button is clicked in Mobile iOS,
 * so that subsequent audio plays are accepted by iOS.
 */
function initialSound(ref) {
  if (ref.current.src) {
    ref.current.muted = true;
    ref.current.play().catch((error) => {});
  }
}

/**
 * Function to play audio in mobile.
 */
function playMobileSound(ref) {
  if (ref.current.src) {
    ref.current.muted = false;
    ref.current.play();
  }
}
/**
 * Use this function call to play audio when it is a Mobile device.
 * Regular audio playing operations do not function in Mobile and need a specific user
 * action to trigger Audio.
 */
export function mobileAudioPlay({
  countdownStatus,
  timerIsRunning,
  timerTime,
  timerId,
  setTimerId,
  defaultAlarmRef,
}) {
  try {
    initialSound(defaultAlarmRef);
    const alarmTime = timerTime * 1000;
    if (!countdownStatus && !timerIsRunning) {
      //set audio timeout for alarm when timer is started.
      setTimerId(setTimeout(() => playMobileSound(defaultAlarmRef), alarmTime));
    } else if (countdownStatus && timerIsRunning) {
      //Clear the previous Timerout when timer is paused.
      clearTimeout(timerId);
    } else {
      //Set audio timeout for alarm when timer is resumed.
      setTimerId(setTimeout(() => playMobileSound(defaultAlarmRef), alarmTime));
    }
  } catch (e) {}
}
