import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import { useEffect } from 'react';
import useMediaSession from '../hooks/useMediaSession';
import { BASE_TIMER_TIME } from '../utilities/Constants';

export default function Root() {
  useMediaSession();
  useEffect(() => {
    window.localStorage.setItem('LastSetTime', BASE_TIMER_TIME.toString());
    zoomSdk.removeVirtualForeground().catch((error) => {
      /*Do Nothing. Currently, as of June 7, 2023, the foreground APIs do it function but still throws an error saying something went wrong.
      Issue with APIs/Client team for fix. An error dialog box can be put in the future here.*/
    });
  }, []);

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
