import { useSelector, useDispatch } from 'react-redux';
import { AlarmOnIcon, AlarmOffIcon } from './Icons/VolumeIcons';
import MusicOptionsList from './MusicOptionsList';
import './SoundButton.css';
import {
  selectIsTimerAudioOn,
  selectedTimerMusicOption,
  selectPreviousSelectedMusicOption,
} from './reducer';
import {
  setTimerAudioState,
  setPreviousSelectedMusicOption,
  setMusicSelectedOption,
} from './actions';
import { useCallback } from 'react';
import useMusicSupportCheckDesktopInMeeting from '../../hooks/Music/useMusicSupportCheckDesktopInMeeting';

// SoundButton component shows Alarm ON/OFF icon and controls its behavior.
// in 4.3 version of timer, music options only supported on desktop. inMainClient: all versions of clients  and inMeeting: clients >=5.16.5
export function SoundButton({ refs }) {
  const timerAudioToggleOn = useSelector(selectIsTimerAudioOn);
  const currentSelectedTimerMusicOption = useSelector(selectedTimerMusicOption);
  const previousSelectedMusicOption = useSelector(
    selectPreviousSelectedMusicOption
  );
  const dispatch = useDispatch();
  const [isMusicSupportedDesktopInMeeting, isMusicSupportedDeskopInMainClient] =
    useMusicSupportCheckDesktopInMeeting();

  // when audio is toggled off store the selected music option as previous. Restore it when audio is toggled on.
  const toggleAudioFunction = useCallback(() => {
    if (timerAudioToggleOn) {
      dispatch(setTimerAudioState(false));
      dispatch(
        setPreviousSelectedMusicOption({
          name: currentSelectedTimerMusicOption.name,
          id: currentSelectedTimerMusicOption.id,
        })
      );
      dispatch(setMusicSelectedOption({ name: 'Off', id: -1 }));
    } else {
      dispatch(setTimerAudioState(true));
      dispatch(
        setMusicSelectedOption({
          name: previousSelectedMusicOption.name,
          id: previousSelectedMusicOption.id,
        })
      );
    }
  }, [
    timerAudioToggleOn,
    currentSelectedTimerMusicOption,
    previousSelectedMusicOption,
  ]);

  return (
    <div className='music_timer'>
      <button
        aria-label={timerAudioToggleOn ? 'Mute Alarm' : 'Unmute Alarm'}
        className='sound_button'
        onClick={() => {
          toggleAudioFunction();
        }}
      >
        {timerAudioToggleOn ? <AlarmOnIcon /> : <AlarmOffIcon />}
      </button>
      <div>
        {isMusicSupportedDesktopInMeeting ||
        isMusicSupportedDeskopInMainClient ? (
          <MusicOptionsList refs={refs} />
        ) : timerAudioToggleOn ? (
          'On'
        ) : (
          'Off'
        )}
      </div>
    </div>
  );
}
