import './NavHeader.css';
import classnames from 'classnames';
import { HOME_TAB, STOPWATCH_TAB } from './constants';
const tabs = {
  home: HOME_TAB,
  stopwatch: STOPWATCH_TAB,
};
function NavHeader({ currentTab, setCurrentTab }) {
  return (
    <div className={'Tabs'}>
      <Tab
        currentTab={currentTab}
        tab={tabs.home}
        tabName={'Timer'}
        setCurrentTab={setCurrentTab}
      />
      <Tab
        currentTab={currentTab}
        tab={tabs.stopwatch}
        tabName={'Stopwatch'}
        setCurrentTab={setCurrentTab}
      />
    </div>
  );
}
function Tab({ currentTab, tab, tabName, setCurrentTab }) {
  const className = classnames('Tab', currentTab === tab && 'active', tab);

  const onClick = () => {
    setCurrentTab(tab);
  };

  return (
    <div className={className} key={tab} onClick={onClick}>
      <button className='Tab__button Link' aria-label={tab}>
        <span className='tab-text'>{tabName}</span>
      </button>
    </div>
  );
}

export default NavHeader;
