import { useSelector } from 'react-redux';
import { selectZaClientVersion } from '../root/reducer';
import { useEffect, useState } from 'react';
import useIsDesktopCheck from './useIsDesktopCheck';

// use this hook to find if the client supports withSound param in DI APIs.
function useWithSound() {
  const clientVersion = useSelector(selectZaClientVersion);
  const isDesktop = useIsDesktopCheck();
  const [clientSupportWithSound, setClientSupportWithSound] = useState('');

  useEffect(() => {
    const [cv1, cv2, cv3] = clientVersion.split('.').map((n) => parseInt(n));
    /*
     withSound parameter in DI is supported in Desktop client >= 5.15.5
     */
    if (isDesktop) {
      if (
        (cv1 === 5 && cv2 === 15 && cv3 >= 5) ||
        (cv1 === 5 && cv2 >= 16) ||
        cv1 >= 6
      ) {
        setClientSupportWithSound(true);
      } else {
        setClientSupportWithSound(false);
      }
    } else {
      //Condition for Mobile check. Though withSound is not supported in mobile,but setting this condition true helps play audio for subscriber DIs.
      setClientSupportWithSound(false);
    }
  }, [clientVersion, isDesktop]);
  return clientSupportWithSound;
}

export default useWithSound;
