import { useCallback, useState } from 'react';
import './NotificationToast.css';
export default function NotificationToast({ title, dismiss, type }) {
  const [closeNotificationTost, setCloseNotificationToast] = useState(false);
  const onCloseFunction = useCallback(() => {
    setCloseNotificationToast(true);
  });
  return (
    <div
      id='notification_toast'
      className={closeNotificationTost ? 'close_notification_toast' : ''}
    >
      <div className='notification_toast_container'>
        <div
          className={`${
            type === 'info' ? 'notification_toast_border' : 'error_toast_border'
          }`}
        ></div>
        <div className='notification_toast_content'>
          <div className='notification_toast_title'>{title}</div>
          {dismiss && (
            <button
              className='notification_exit'
              aria-label='Exit Toast'
              onClick={onCloseFunction}
            >
              <svg
                width='32'
                height='32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  className='close_button'
                  d='M0 12.8c0-4.48 0-6.72.872-8.432A8 8 0 0 1 4.368.872C6.08 0 8.32 0 12.8 0h6.4c4.48 0 6.72 0 8.432.872a8 8 0 0 1 3.496 3.496C32 6.08 32 8.32 32 12.8v6.4c0 4.48 0 6.72-.872 8.432a8 8 0 0 1-3.496 3.496C25.92 32 23.68 32 19.2 32h-6.4c-4.48 0-6.72 0-8.432-.872a8 8 0 0 1-3.496-3.496C0 25.92 0 23.68 0 19.2v-6.4Z'
                  fill='none'
                />
                <mask
                  id='toastMask'
                  style={{ maskType: 'alpha' }}
                  maskUnits='userSpaceOnUse'
                  x='10'
                  y='10'
                  width='12'
                  height='12'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M20.99 11.003c.344.34.347.895.007 1.238l-3.765 3.8 3.682 3.718a.875.875 0 1 1-1.243 1.232L16 17.285l-3.671 3.706a.875.875 0 1 1-1.243-1.232l3.682-3.718-3.765-3.8a.875.875 0 1 1 1.244-1.232L16 14.8l3.753-3.79a.875.875 0 0 1 1.238-.006Z'
                    fill='#000001'
                  />
                </mask>
                <g mask='url(#toastMask)'>
                  <path fill='#ffffff' d='M9 9h14v14H9z' />
                </g>
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
