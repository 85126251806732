import { createAction } from '@reduxjs/toolkit';

export const setTimerAudioState = createAction('audio/toggle', (status) => {
  return { payload: status };
});

export const setMusicSelectedOption = createAction(
  'audio/selectedMusicOption',
  (obj) => {
    return { payload: obj };
  }
);

export const setPreviousSelectedMusicOption = createAction(
  'audio/previousSelectedOption',
  (obj) => {
    return { payload: obj };
  }
);

export const setCurrentPreviewMusic = createAction('audio/preview', (id) => {
  return { payload: id };
});
