/* PlayIcon is used in music options list to preview music*/
function PlayIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.65039 17.7832C7.78711 17.7832 7.91699 17.7581 8.04004 17.708C8.16309 17.6624 8.29297 17.5986 8.42969 17.5166L16.4004 12.9092C16.6875 12.7406 16.8857 12.5924 16.9951 12.4648C17.109 12.3327 17.166 12.1755 17.166 11.9932C17.166 11.8109 17.109 11.6559 16.9951 11.5283C16.8857 11.3962 16.6875 11.248 16.4004 11.084L8.42969 6.46973C8.29297 6.39225 8.16309 6.33073 8.04004 6.28516C7.91699 6.23503 7.78711 6.20996 7.65039 6.20996C7.39974 6.20996 7.19922 6.29883 7.04883 6.47656C6.90299 6.6543 6.83008 6.89128 6.83008 7.1875V16.7988C6.83008 17.0951 6.90299 17.332 7.04883 17.5098C7.19922 17.6921 7.39974 17.7832 7.65039 17.7832Z'
        fill='#F7F9FA'
      />
      <rect x='0.5' y='0.5' width='23' height='23' rx='5.5' stroke='#6E7680' />
    </svg>
  );
}

export default PlayIcon;
