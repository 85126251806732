export const GREEN = '#23D959';
const YELLOW = '#FFBF39';
const RED = '#ED4D6A';
export const WHITE = '#ffffff';
export const BLACK = '#242424';
const BROWN_RED = '#43131C';

//Function to determine Test Color
export function determineTextColor(timerTime) {
  return WHITE;
}

//Function to determine Background Color
export function determineBackgroundColor(timerTime) {
  if (timerTime < 0) {
    return BROWN_RED;
  } else {
    return BLACK;
  }
}

export function determineTimerColor(timerTime, startTime) {
  if (timerTime < 0) {
    return RED;
  } else {
    const percentage = timerTime / startTime;
    if (percentage <= 0.25) return RED;
    else if (percentage <= 0.4) return YELLOW;
    else return GREEN;
  }
}

export function formatTime(time) {
  let rawHours;
  let rawMinutes;
  time >= 0
    ? (rawHours = Math.floor(time / 3600))
    : (rawHours = Math.ceil(time / 3600));
  time >= 0
    ? (rawMinutes = Math.floor((time % 3600) / 60))
    : (rawMinutes = Math.ceil((time % 3600) / 60));
  let rawSeconds = (time % 3600) % 60;

  let hours = rawHours.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  let minutes = rawMinutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  let seconds = rawSeconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return { hours: hours, minutes: minutes, seconds: seconds };
}
